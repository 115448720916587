import firebase from "firebase/compat/app";
import moment from "moment";
import { DeepMap, FieldError } from "react-hook-form";
import {
  AudienceType,
  BannerDisplayableSegment,
  DisplayUnitType,
  NotificationTopics,
  RemoteNotificationRecordType,
  RoleTypes,
  UserSegmantType,
} from ".";
import { theme } from "../components/theme/util/theme";
import {
  NUM_OF_DAYS_FOR_CHILD,
  NUM_OF_DAYS_FOR_PREG,
  NUM_OF_MONTHS,
  NUM_OF_WEEKS,
  validationError,
} from "./consts";

export interface LanguageDefinition {
  priority: number;
  shortName: string;
  displayName: string;
}

export const yesOrNo = (answer: boolean) => {
  return answer ? "Yes" : "No";
};

export const getLanguages = (): LanguageDefinition[] => {
  const english: LanguageDefinition = {
    priority: 1,
    shortName: "sv",
    displayName: "Swedish",
  };
  const swedish: LanguageDefinition = {
    priority: 2,
    shortName: "en",
    displayName: "English",
  };
  const norwegian: LanguageDefinition = {
    priority: 3,
    shortName: "no",
    displayName: "Norwegian",
  };
  const danish: LanguageDefinition = {
    priority: 4,
    shortName: "da",
    displayName: "Danish",
  };
  const german: LanguageDefinition = {
    priority: 5,
    shortName: "de",
    displayName: "German",
  };
  const languages: LanguageDefinition[] = [
    swedish,
    english,
    norwegian,
    danish,
    german,
  ];
  return languages;
};

export const getRemoteNotificationTopics = () => {
  const topicOptions = [
    {
      key: NotificationTopics.Pregnancy,
      value: NotificationTopics.Pregnancy,
      display: NotificationTopics.Pregnancy,
    },
    {
      key: NotificationTopics.Children,
      value: NotificationTopics.Children,
      display: NotificationTopics.Children,
    },
    {
      key: NotificationTopics.Offer,
      value: NotificationTopics.Offer,
      display: NotificationTopics.Offer,
    },
    {
      key: NotificationTopics.Partner,
      value: NotificationTopics.Partner,
      display: NotificationTopics.Partner,
    },
    {
      key: NotificationTopics.Reminder,
      value: NotificationTopics.Reminder,
      display: NotificationTopics.Reminder,
    },
    {
      key: NotificationTopics.Update,
      value: NotificationTopics.Update,
      display: NotificationTopics.Update,
    },
  ];
  return topicOptions;
};

export const getUserSegments = () => {
  const topicOptions = [
    {
      key: UserSegmantType.childrenInMonthsBetween,
      value: UserSegmantType.childrenInMonthsBetween,
      display: "Users with children in months between",
    },
    {
      key: UserSegmantType.childrenInOddMonths,
      value: UserSegmantType.childrenInOddMonths,
      display: "Users with children in odd months",
    },
    {
      key: UserSegmantType.childrenInEvenMonths,
      value: UserSegmantType.childrenInEvenMonths,
      display: "Users with children in even months",
    },
    {
      key: UserSegmantType.pregnanciesInOrAboveWeekNumber,
      value: UserSegmantType.pregnanciesInOrAboveWeekNumber,
      display: "Users with pregnancies in weeks between",
    },
    {
      key: UserSegmantType.usersWithPartners,
      value: UserSegmantType.usersWithPartners,
      display: "Has a partner",
    },
    {
      key: UserSegmantType.usersWithSelectedTown,
      value: UserSegmantType.usersWithSelectedTown,
      display: "Has set their home town to ",
    },
    {
      key: UserSegmantType.usersWithCategorySpecified,
      value: UserSegmantType.usersWithCategorySpecified,
      display: "Has specified interest",
    },
    {
      key: UserSegmantType.usersNotParticipatedInCompetition,
      value: UserSegmantType.usersNotParticipatedInCompetition,
      display: "Has not participated in particular competition",
    },
    {
      key: UserSegmantType.allUsers,
      value: UserSegmantType.allUsers,
      display: "All Users",
    },
  ];
  return topicOptions;
};

export const getAudienceTypes = () => {
  const audienceTypeOptions = [
    {
      key: AudienceType.All,
      value: AudienceType.All,
      display: "All",
    },
    {
      key: AudienceType.Child,
      value: AudienceType.Child,
      display: "Child",
    },
    {
      key: AudienceType.Pregnancy,
      value: AudienceType.Pregnancy,
      display: "Pregnancy",
    },
    {
      key: AudienceType.Partner,
      value: AudienceType.Partner,
      display: "Partner",
    },
  ];
  return audienceTypeOptions;
};
export const getAudienceTypesWithoutPartner = () => {
  return getAudienceTypes().filter(el => el.value != AudienceType.Partner);
};

export const getDisplayUnits = (audienceTypes: AudienceType) => {
  const displayUnitsOptions = [
    {
      key: DisplayUnitType.Day,
      value: DisplayUnitType.Day,
      display: "Days",
    },
    {
      key: DisplayUnitType.Week,
      value: DisplayUnitType.Week,
      display: "Weeks",
    },
    {
      key: DisplayUnitType.Month,
      value: DisplayUnitType.Month,
      display: "Months",
    },
  ];

  switch (audienceTypes) {
    case AudienceType.Child:
      return displayUnitsOptions.filter(
        ({ value }) => value !== DisplayUnitType.Week
      );
    case AudienceType.Pregnancy:
      return displayUnitsOptions.filter(
        ({ value }) => value !== DisplayUnitType.Month
      );
    case AudienceType.All:
      return displayUnitsOptions.filter(
        ({ value }) => value === DisplayUnitType.Day
      );
    case AudienceType.Partner:
      return displayUnitsOptions.filter(
        ({ value }) => value === DisplayUnitType.Day
      );
    default:
      return displayUnitsOptions;
  }
};

export const getMobileBannerSegments = () => {
  const topicOptions = [
    {
      key: BannerDisplayableSegment.childrenWithInMonthsRange,
      value: BannerDisplayableSegment.childrenWithInMonthsRange,
      display: "Users with children in months between",
    },
    {
      key: BannerDisplayableSegment.childrenWithInWeeksRange,
      value: BannerDisplayableSegment.childrenWithInWeeksRange,
      display: "Users with children in weeks between",
    },
    {
      key: BannerDisplayableSegment.pregnanciesWithInWeeksRange,
      value: BannerDisplayableSegment.pregnanciesWithInWeeksRange,
      display: "Users with pregnancies in weeks between",
    },
    {
      key: BannerDisplayableSegment.hasLoyalty,
      value: BannerDisplayableSegment.hasLoyalty,
      display: "Has a loyalty",
    },
    {
      key: BannerDisplayableSegment.hasPartner,
      value: BannerDisplayableSegment.hasPartner,
      display: "Has a partner",
    },
    {
      key: BannerDisplayableSegment.allUsers,
      value: BannerDisplayableSegment.allUsers,
      display: "All users",
    },
  ];
  return topicOptions;
};

export const formatTimeStampToDateString = (timeStamp: any) => {
  if (!timeStamp) return null;
  return moment(timeStamp?.toDate()).format("yyyy-MM-DD").toString();
};

export const formatTimeStampToMoment = (
  timeStamp: firebase.firestore.Timestamp
) => {
  if (!timeStamp) return null;
  return moment(timeStamp?.toDate());
};

export const formatTimeStampToDate = (
  timeStamp?: firebase.firestore.Timestamp
) => {
  if (!timeStamp) return null;
  return timeStamp.toDate();
};

export const formatDateStringToTimeStamp = (
  dateString: string,
  withoutTime: boolean
) => {
  if (!dateString) return null;
  const parsedTime = withoutTime
    ? parseDateWithoutTime(dateString)
    : new Date(dateString);
  return parsedTime ? firebase.firestore.Timestamp.fromDate(parsedTime) : null;
};

export const formatDate = (timeStamp: any, format = "DD/MM/YYYY") => {
  if (!timeStamp) return "";
  try {
    const conveted =
      timeStamp._seconds * 1000 + timeStamp._nanoseconds / 100000;
    const formatted = moment(new Date(conveted)).format(format);
    return formatted;
  } catch (ex) {
    console.log(ex);
  }
  return "";
};

export const formatStringDate = (timeStamp: string) => {
  if (timeStamp) {
    return moment(new Date(timeStamp)).format("DD/MM/YYYY");
  }
  return "";
};

export const formatMomentToStringDate = (timeStamp: moment.Moment) => {
  if (timeStamp) {
    return timeStamp.format("DD/MM/YYYY hh:mm");
  }
  return "";
};

export const generateFormError = (error: any) => {
  const generalError = "Error occured!";
  const errorMsg: string = error?.message ?? "";
  if (!errorMsg) return generalError;
  if (errorMsg === validationError) {
    return "Fix the validation errors and try again!";
  } else {
    const serverErrorPrefix = "";
    if (!errorMsg) return `${serverErrorPrefix}${generalError}`;
    const [prefix, suffix] = errorMsg?.split(":");
    return `${serverErrorPrefix} ${
      suffix ? prefix ?? generalError : prefix ?? generalError
    }`;
  }
};

export const generateServerError = (error: any) => {
  const generalError = "Server error occured!";
  const responseMessage = error.message;
  return responseMessage ?? generalError;
};

export const getFormattedErrorMessage = (error: any) => {
  const generalError = "Error occured!";
  console.log(error);
  const errorMsg: string = error?.message ?? generalError;
  return errorMsg;
};

export const formValidationError = (errors: DeepMap<any, FieldError>) => {
  throw new Error(validationError);
};

export const getUserRoles = () => {
  const postTypes: { text: string; key: string; value: RoleTypes }[] = [
    {
      text: "moderator",
      value: RoleTypes.moderator,
      key: "moderator",
    },
    {
      text: "admin",
      value: RoleTypes.admin,
      key: "admin",
    },
    {
      text: "analytic",
      value: RoleTypes.analytic,
      key: "analytic",
    },
    {
      text: "notificationHandler",
      value: RoleTypes.notificationHandler,
      key: "analytic",
    },
  ];
  return postTypes;
};

export const generateRandomNumber = () => Math.random().toString(36).slice(2);

export const compareTexts = (firstString: string, secondString: string) => {
  return (
    firstString
      ?.toLocaleLowerCase()
      ?.localeCompare(secondString.toLocaleLowerCase()) === 0
  );
};

export const filterUnnessaryTags = (allTags: Tags[], tagNames: string[]) => {
  tagNames.filter(x => allTags.find(y => y.name === x));
};

export const parseDateWithoutTime = (dateString: string) => {
  try {
    const parsedDate = new Date(dateString);
    return new Date(parsedDate.toDateString());
  } catch (e) {
    return null;
  }
};

export const convertBytesToMbs = (size: number) => size / 1024 ** 2;

export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const formatRemoteNotificationMessage = (
  status: RemoteNotificationRecordType
) => {
  let notificationStatus: RemoteNotificationMessageType = {};
  switch (status) {
    case RemoteNotificationRecordType.draft:
      notificationStatus = {
        shortMessage: "Draft",
        message:
          "This is saved as draft, you can edit, or publish by mofifying topics or user segments",
        type: "info",
        color: theme.button.draft,
      };
      break;
    case RemoteNotificationRecordType.error:
      notificationStatus = {
        shortMessage: "Failed",
        message:
          "Error occured while sending this notification, refer log for more information",
        type: "error",
        color: theme.button.danger,
      };
      break;
    case RemoteNotificationRecordType.completed:
      notificationStatus = {
        shortMessage: "Completed",
        message: "Notification sent successfully",
        type: "success",
        color: theme.button.success,
      };
      break;
    case RemoteNotificationRecordType.pending:
      notificationStatus = {
        shortMessage: "Pending",
        message:
          "This record is still processing, state will update when server completes the task",
        type: "warning",
        color: theme.button.pending,
      };

      break;
    case RemoteNotificationRecordType.scheduled:
      notificationStatus = {
        shortMessage: "Scheduled",
        message:
          "This record is scheduled and will automatically execute at given time",
        type: "info",
        color: theme.button.primary,
      };

      break;
    case RemoteNotificationRecordType.schedulePending:
      notificationStatus = {
        shortMessage: "Pending",
        message: "This record sent for scheduling",
        type: "warning",
        color: theme.button.pending,
      };

      break;
    case RemoteNotificationRecordType.scheduleDeletePending:
      notificationStatus = {
        shortMessage: "Pending",
        message: "This record is scheduled but pending delete",
        type: "warning",
        color: theme.button.pending,
      };

      break;
    case RemoteNotificationRecordType.scheduleDeleted:
      notificationStatus = {
        shortMessage: "Deleted",
        message: "This record is scheuled but then deleted",
        type: "info",
        color: theme.button.danger,
      };

      break;
    default:
      notificationStatus = {
        shortMessage: "Unknown",
        message: "No status recorded",
        type: "info",
      };
  }
  return notificationStatus;
};

export const getMaxSortOrder = <T extends { sortOrder?: number }>(
  contents: T[]
) => {
  const sortOrders = contents?.map(x => x.sortOrder);
  const maxSortOrder = Math.max(...sortOrders, -1);
  return maxSortOrder;
};

export const swapSortOrder = <T extends { sortOrder?: number }>(
  contents: T[],
  oldIndex: number,
  newIndex: number
) => {
  const sortableContents = [...contents];
  const sortOrder = sortableContents[oldIndex]?.sortOrder;
  sortableContents[oldIndex].sortOrder = sortableContents[newIndex]?.sortOrder;
  sortableContents[newIndex].sortOrder = sortOrder;
  return sortableContents;
};

export const getMaxRangeFor = (
  audience: AudienceType,
  rangeUnit: DisplayUnitType
) => {
  switch (rangeUnit) {
    case DisplayUnitType.Day:
      return audience === AudienceType.Child
        ? NUM_OF_DAYS_FOR_CHILD
        : NUM_OF_DAYS_FOR_PREG;
    case DisplayUnitType.Week:
      return NUM_OF_WEEKS;
    case DisplayUnitType.Month:
      return NUM_OF_MONTHS;
    default:
      return 0;
  }
};

export const convertListsToObject = (
  list: string[]
): { [key: string]: boolean } => {
  return list.reduce((acc, currentValue) => {
    acc[currentValue] = true;
    return acc;
  }, {} as { [key: string]: boolean });
};
