import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Col, Divider, Form, Input, List, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import { FormEdit, FormEditType } from "../../components";
import { DropAndCrop } from "../../components/DropAndCrop";
import { BJSelectFormItem } from "../../components/theme";
import BJInput from "../../components/theme/atoms/BJInput";
import BJButton from "../../components/theme/atoms/Button";
import { useCountry, useMilestone, useMilestones } from "../../context";
import {
  commonErrors,
  curatedPageMessages,
  milestonePageMessages,
} from "../../language";
import {
  AspectRatio,
  DisplayUnitType,
  HALF_YEARLY_MONTHS,
  MeasurementSystem,
  MilestonesType,
  formValidationError,
  getFormattedErrorMessage,
  getOrdinal,
  isEqualArrays,
} from "../../utils";
import { DayModal } from "./";
import convert, { MeasuresByUnit, Unit } from "convert";
import { BJInputNumberFormItem } from "../../components/theme/molecules/formItems/BJInputNumberFormItem";
import { TranslationSection } from "../../components/TranslationSection";
import { SetValueFunction, TranslationsObject } from "../../types/translation";

type FormValues = {
  periodType: string;
  period: number;
  imageUrlFetus: string;
  blurhashFetus?: string;
  imageUrlFruit: string;
  blurhashFruit?: string;
  translations?: {
    [locale: string]: {
      text: string;
      imageUrl: string;
      blurhash?: string;
    };
  };
  weight: number;
  weightUnit: string;
  length: number;
  lengthUnit: string;
  weightLb: number;
  weightOz: number;
  lengthFt: number;
  lengthIn: number;
};

export const minimumWeek = 0;
export const minimumWeekPregnancy = 1;
export const maximumWeek = 13;
export const maximumWeekPregnancy = 45;
export const minimumMonth = 3;
export const maximumMonth = 24;
export const defaultPeriod = DisplayUnitType.Week;

const mapToOption = (value: string) => ({
  key: value,
  value,
  display: value,
});

export const timelineOptions = [
  {
    key: DisplayUnitType.Week,
    value: DisplayUnitType.Week,
    display: "Week",
  },
  {
    key: DisplayUnitType.Month,
    value: DisplayUnitType.Month,
    display: "Month",
  },
];

const { urlValidationError2: urlError } = commonErrors;

export const MilestonePage = () => {
  const {
    milestonesPregnancy,
    milestonesChildWeek,
    milestonesChildMonth,
    milestonesService,
  } = useMilestones();
  const { id } = useParams<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentCountry, primaryLocale } = useCountry();
  const searchParams = new URLSearchParams(location.search);
  const getMilestoneType = searchParams.get("type"); //it can be a child or pregnancy
  const isPregnancyPage = location.pathname.includes(MilestonesType.Pregnancy);
  const isChildPage = location.pathname.includes(MilestonesType.Child);

  const { measurementSystem } = currentCountry;
  const isMetric = measurementSystem.name === MeasurementSystem.Metric;

  const weightUnitOptions = measurementSystem.weightUnits.map(mapToOption);
  const lengthUnitOptions = measurementSystem.lengthUnits.map(mapToOption);

  const schema = yup.object().shape({
    periodType: isPregnancyPage
      ? yup.string()
      : yup
          .string()
          .required(
            `Period type: ${milestonePageMessages.requiredValidationText}`
          ),
    period: isChildPage
      ? yup
          .number()
          .typeError(`Period: ${milestonePageMessages.numberTypeErrorMessage}`)
          .required(`Period: ${milestonePageMessages.requiredValidationText}`)
          .test(
            "RecordNotInRange",
            curatedPageMessages.numberNotInRangeErrorMessage,
            function (value: number | undefined): boolean {
              const periodType = this.parent.periodType;
              let notInRange = false;

              if (value && HALF_YEARLY_MONTHS.includes(value)) {
                return true;
              }

              if (periodType === defaultPeriod) {
                notInRange = value! >= minimumWeek && value! <= maximumWeek;
              } else {
                notInRange = value! >= minimumMonth && value! <= maximumMonth;
              }

              return notInRange;
            }
          )
      : yup
          .number()
          .typeError(`Period: ${milestonePageMessages.numberTypeErrorMessage}`)
          .min(
            minimumWeekPregnancy,
            milestonePageMessages.dateRangeValidationText
          )
          .max(
            maximumWeekPregnancy,
            milestonePageMessages.dateRangeValidationText
          )
          .required(`Period: ${milestonePageMessages.requiredValidationText}`),
    length: yup
      .number()
      .min(0)
      .typeError(
        `Baby length: ${milestonePageMessages.numberTypeErrorMessage}`
      ),
    weight: yup
      .number()
      .min(0)
      .typeError(`Weight: ${milestonePageMessages.numberTypeErrorMessage}`),
    weightUnit:
      isChildPage || !isMetric
        ? yup.string()
        : yup
            .string()
            .required(
              `Weight unit: ${milestonePageMessages.requiredValidationText}`
            ),
    lengthUnit:
      isChildPage || !isMetric
        ? yup.string()
        : yup
            .string()
            .required(
              `Length unit: ${milestonePageMessages.requiredValidationText}`
            ),
    weightLb: yup.number().min(0),
    weightOz: yup.number().min(0).max(15, "Oz: Max value is 15"),
    lengthFt: yup.number().min(0),
    lengthIn: yup.number().min(0).max(11, "In: Max value is 11"),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          text: yup
            .string()
            .required(
              `Text (${String(item.key).toUpperCase()}): ${
                milestonePageMessages.requiredValidationText
              }`
            ),
          imageUrl: yup.string().nullable().url(`Image URL: ${urlError}`),
        });
        return acc;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, {} as any)
    ),
  });

  const {
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [error, setError] = useState<string | null>(null);
  const [showAddDay, setShowAddDay] = useState(false);
  const [selectedDay, setSelectedDay] = useState<MilestoneDay | null>(null);
  const [days, setDays] = useState<MilestoneDay[]>([]);
  const period = watch("period");
  const periodType = watch("periodType");
  const translations = watch("translations");

  const isChildMilestonewithMonth = isChildPage && periodType !== defaultPeriod;
  const isChildMilestonewithWeek = isChildPage && periodType === defaultPeriod;
  const { milestone, loading } = useMilestone(
    id,
    getMilestoneType,
    isChildPage
  );

  useEffect(() => {
    if (!milestone) {
      reset({
        translations: milestone?.translations ?? {},
        period: 0,
        imageUrlFetus: null,
        blurhashFetus: null,
        imageUrlFruit: null,
        blurhashFruit: null,
        periodType: defaultPeriod,
      });
      setDays([]);
    } else {
      setDays(milestone.days);
      getMilestoneType && getMilestoneType !== defaultPeriod //case for child monthly
        ? reset({
            translations: milestone?.translations ?? {},
            period: milestone?.month,
            periodType: "month",
          })
        : getMilestoneType && getMilestoneType === defaultPeriod // case for child weekly
        ? reset({
            translations: milestone?.translations ?? {},
            period: milestone?.week,
            periodType: defaultPeriod,
          })
        : reset({
            // case for pregnancy weekly
            translations: milestone?.translations ?? {},
            imageUrlFetus: milestone?.imageUrlFetus ?? null,
            blurhashFetus: milestone?.blurhashFetus ?? null,
            imageUrlFruit: milestone?.imageUrlFruit ?? null,
            blurhashFruit: milestone?.blurhashFruit ?? null,
            period: milestone?.month ?? milestone?.week,
            periodType: milestone?.month
              ? DisplayUnitType.Month
              : defaultPeriod,
            ...(milestone.measurements
              ? milestone.measurements.meta.originalSystem ===
                MeasurementSystem.Imperial
                ? {
                    weightLb: milestone.measurements.meta.weightLb,
                    weightOz: milestone.measurements.meta.weightOz,
                    lengthFt: milestone.measurements.meta.lengthFt,
                    lengthIn: milestone.measurements.meta.lengthIn,
                    weightUnit: milestone.measurements.weight.displayUnit,
                    lengthUnit: milestone.measurements.length.displayUnit,
                  }
                : {
                    weight: convert(
                      milestone.measurements.weight.value,
                      "g"
                    ).to(
                      milestone.measurements.weight
                        .displayUnit as MeasuresByUnit<"g">
                    ),
                    length: convert(
                      milestone.measurements.length.value,
                      "mm"
                    ).to(
                      milestone.measurements.length
                        .displayUnit as MeasuresByUnit<"mm">
                    ),
                    weightUnit: milestone.measurements.weight.displayUnit,
                    lengthUnit: milestone.measurements.length.displayUnit,
                  }
              : {}),
          });
    }
    setError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestone, reset, id]);

  useEffect(() => {
    setError(null);
  }, [period]);

  const onRemove = async () => {
    if (milestone) {
      try {
        milestonesService.deleteQuestion(
          milestone.id,
          isChildPage,
          milestone?.month ? true : false
        );
        return navigate("./..");
      } catch (error) {
        const message = getFormattedErrorMessage(error);
        setError(message);
      }
    }
  };

  const onAddDay = (day: MilestoneDay) => {
    setDays(old => [...old, day]);
    setShowAddDay(false);
  };

  const onUpdateDay = (day: MilestoneDay) => {
    setDays(old => old.map(d => (d.id === day.id ? day : d)));
    setSelectedDay(null);
  };

  const onDeleteDay = (id: Checklist["id"]) => {
    setDays(old => old.filter(d => d.id !== id));
    setSelectedDay(null);
  };

  const handleImageUrl = (url: string, locale: string) => {
    setValue(`translations.${locale}.imageUrl`, url as string, {
      shouldDirty: true,
    });
  };

  const handleFetusImageUrl = (url: string | null) => {
    setValue("imageUrlFetus", url!, { shouldDirty: true });
  };

  const handleFruitImageUrl = (url: string | null) => {
    setValue("imageUrlFruit", url!, { shouldDirty: true });
  };

  const handleBlurhash = (url: string, locale: string) => {
    setValue(`translations.${locale}.blurhash`, url as string, {
      shouldDirty: true,
    });
  };

  const handleFetusBlurhash = (url: string | null) => {
    setValue("blurhashFetus", url!, { shouldDirty: true });
  };

  const handleFruitBlurhash = (url: string | null) => {
    setValue("blurhashFruit", url!, { shouldDirty: true });
  };

  const onSubmit: SubmitHandler<FormValues> = async data => {
    try {
      const {
        period,
        imageUrlFruit,
        imageUrlFetus,
        blurhashFetus,
        blurhashFruit,
      } = data;

      const translations: Milestone["translations"] = {};

      for (const [key, value] of Object.entries(data.translations)) {
        translations[key] = {
          text: value.text,
          imageUrl: value.imageUrl,
          blurhash: value.blurhash,
        };
      }

      const milestones = isChildMilestonewithMonth
        ? milestonesChildMonth
        : isChildMilestonewithWeek
        ? milestonesChildWeek
        : milestonesPregnancy;

      const existingPeriod = isChildMilestonewithMonth
        ? milestones.flatMap(({ month }) => month)
        : milestones.flatMap(({ week }) => week);

      const milestoneData: Omit<Milestone, "translations"> =
        isChildMilestonewithMonth
          ? {
              month: period,
              imageUrl: translations[primaryLocale.key].imageUrl || null,
              blurhash: translations[primaryLocale.key].blurhash || null,
              days,
            }
          : isChildMilestonewithWeek
          ? {
              week: period,
              imageUrl: translations[primaryLocale.key].imageUrl || null,
              blurhash: translations[primaryLocale.key].blurhash || null,
              days,
            }
          : {
              week: period,
              imageUrl: translations[primaryLocale.key].imageUrl || null,
              blurhash: translations[primaryLocale.key].blurhash || null,
              days,
              imageUrlFruit,
              blurhashFruit,
              imageUrlFetus,
              blurhashFetus,
            };

      let weight = 0,
        length = 0;
      let meta = {} as MilestoneMeasurementMeta;

      if (isPregnancyPage) {
        if (measurementSystem.name === MeasurementSystem.Imperial) {
          const { weightLb, weightOz, lengthIn, lengthFt } = data;
          weight =
            convert(weightLb ?? 0, "lb").to("g") +
            convert(weightOz ?? 0, "oz").to("g");
          length =
            convert(lengthIn ?? 0, "in").to("mm") +
            convert(lengthFt ?? 0, "ft").to("mm");
          meta = {
            weightLb,
            weightOz,
            lengthIn,
            lengthFt,
            originalSystem: MeasurementSystem.Imperial,
          };
        } else {
          weight = convert(data.weight, data.weightUnit as Unit).to("g");
          length = convert(data.length, data.lengthUnit as Unit).to("mm");
          milestoneData.weight = data.weight;
          milestoneData.length = data.length;
          milestoneData.weightUnit = data.weightUnit;
          milestoneData.lengthUnit = data.lengthUnit;
          meta = {
            originalSystem: MeasurementSystem.Metric,
          };
        }
        milestoneData.measurements = {
          weight: {
            value: weight,
            unit: "g",
            displayUnit: data.weightUnit,
          },
          length: {
            value: length,
            unit: "mm",
            displayUnit: data.lengthUnit,
          },
          meta,
        };
      }

      if (!milestone) {
        if (existingPeriod.includes(period)) {
          setError(
            isChildMilestonewithMonth
              ? milestonePageMessages.existingMonthError
              : milestonePageMessages.existingWeekError
          );
          throw new Error(
            isChildMilestonewithMonth
              ? milestonePageMessages.existingMonthError
              : milestonePageMessages.existingWeekError
          );
        }
        const { id: milestoneId } = await milestonesService.create(
          { ...milestoneData, translations },
          isChildPage
        );
        return navigate(`../${milestoneId}`);
      } else {
        if (
          (milestone.month ?? milestone.week) !== period &&
          existingPeriod.includes(period)
        ) {
          setError(
            isChildMilestonewithMonth
              ? milestonePageMessages.existingMonthError
              : milestonePageMessages.existingWeekError
          );
          throw new Error(
            isChildMilestonewithMonth
              ? milestonePageMessages.existingMonthError
              : milestonePageMessages.existingWeekError
          );
        }
        await milestonesService.update(
          milestone.id,
          { ...milestoneData, translations },
          isChildPage
        );
      }
      setError(null);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
      console.log(err);
      throw new Error(message);
    }
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    !isEqualArrays(milestone?.days ?? [], days);

  return (
    <>
      <FormEdit
        onRemove={onRemove}
        error={error}
        enableSave={isDirty}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        title={
          milestone && isChildMilestonewithMonth
            ? `${getOrdinal(milestone?.month)} Month`
            : milestone
            ? `${getOrdinal(milestone?.week)} Week`
            : "New Milestone"
        }
        id={milestone?.id}
        editType={milestone?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loading}
        localeSupported
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors={errors as any}
      >
        {locale => (
          <Row gutter={{ md: 20 }}>
            <Col span={12}>
              {isChildPage && (
                <BJSelectFormItem
                  size="large"
                  control={control}
                  error={!!errors.periodType}
                  label={"Period Type(week/month)"}
                  message={errors.periodType?.message}
                  defaultValue={periodType}
                  optionsList={timelineOptions}
                  fieldName={"periodType"}
                  required
                  includeEmpty
                  disabled={!!milestone?.id}
                />
              )}

              <Form.Item
                label={periodType !== defaultPeriod ? "Month" : "Week"}
                validateStatus={errors.period && "error"}
                extra={
                  <Typography.Paragraph type="danger">
                    {errors.period?.message}
                  </Typography.Paragraph>
                }
              >
                <Controller
                  control={control}
                  name="period"
                  render={({ field }) => <BJInput {...field} />}
                />
              </Form.Item>
              <Form.Item
                label={`Text (${locale?.label})`}
                validateStatus={
                  errors.translations?.[locale?.key]?.text && "error"
                }
                extra={
                  <Typography.Paragraph type="danger">
                    {errors.translations?.[locale?.key]?.text?.message}
                  </Typography.Paragraph>
                }
                required
              >
                <Controller
                  control={control}
                  name={`translations.${locale?.key}.text`}
                  key={`translations.${locale?.key}.text`}
                  render={({ field: { onChange, value } }) => (
                    <Input.TextArea
                      rows={4}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Form.Item>
              <div>
                <Typography.Text>{`Image URL (${locale?.label})`}</Typography.Text>
                <DropAndCrop
                  title=""
                  setUploadUrl={(url: string) =>
                    handleImageUrl(url, locale.key)
                  }
                  setBlurhash={(url: string) => handleBlurhash(url, locale.key)}
                  uploadImage={milestonesService.uploadMilestoneImage}
                  initialUrl={watch(`translations.${locale.key}.imageUrl`)}
                  fileNamePrefix={milestone?.id}
                  defaultCropBoxWidth={800}
                  key={`translations.${locale.key}.imageUrl`}
                />
              </div>
              {isPregnancyPage && (
                <>
                  <div>
                    <Typography.Text>Fetus URL</Typography.Text>
                    <DropAndCrop
                      allowNaturalImageUpload={true}
                      title="Banner Image"
                      initialUrl={milestone?.imageUrlFetus}
                      setUploadUrl={handleFetusImageUrl}
                      setBlurhash={handleFetusBlurhash}
                      fileNamePrefix={milestone?.id}
                      uploadImage={milestonesService.uploadMilestoneImage}
                      lockedRatio={AspectRatio.Free}
                      defaultCropBoxWidth={100}
                      defaultCropBoxHeight={100}
                      croppable={false}
                    />
                  </div>
                  <div>
                    <Typography.Text>Fruit URL</Typography.Text>
                    <DropAndCrop
                      allowNaturalImageUpload={true}
                      fileNamePrefix={milestone?.id}
                      title="Banner Image"
                      initialUrl={milestone?.imageUrlFruit}
                      setUploadUrl={handleFruitImageUrl}
                      setBlurhash={handleFruitBlurhash}
                      uploadImage={milestonesService.uploadMilestoneImage}
                      lockedRatio={AspectRatio.Free}
                      defaultCropBoxWidth={100}
                      defaultCropBoxHeight={100}
                      croppable={false}
                    />
                  </div>
                </>
              )}
            </Col>

            <Col span={12}>
              <>
                <TranslationSection
                  locale={locale}
                  primaryLocale={primaryLocale}
                  translations={translations as TranslationsObject}
                  setValue={setValue as SetValueFunction}
                  availableFields={["text"]}
                  requiredFields={["text"]}
                />
                {isPregnancyPage && (
                  <>
                    <Row style={{ flexGrow: 1 }} gutter={16}>
                      {measurementSystem.name === MeasurementSystem.Metric ? (
                        <>
                          <Col span={12}>
                            <BJInputNumberFormItem
                              control={control}
                              error={!!errors.length}
                              label={"Baby length"}
                              message={errors.length?.message}
                              fieldName={"length"}
                              type="number"
                            />
                          </Col>
                          <Col span={12}>
                            <BJSelectFormItem
                              size="large"
                              control={control}
                              error={!!errors.lengthUnit}
                              label={`Unit(${measurementSystem.lengthUnits.join(
                                "/"
                              )})`}
                              message={errors.lengthUnit?.message}
                              defaultValue={
                                milestone?.lengthUnit ??
                                measurementSystem.lengthUnits[0]
                              }
                              optionsList={lengthUnitOptions}
                              fieldName={"lengthUnit"}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={8}>
                            <BJInputNumberFormItem
                              control={control}
                              error={!!errors.length}
                              label={"Baby length (ft)"}
                              message={errors.length?.message}
                              fieldName={"lengthFt"}
                              type="number"
                              style={{ flex: "1 1 0" }}
                            />
                          </Col>
                          <Col span={8}>
                            <BJInputNumberFormItem
                              control={control}
                              error={!!errors.length}
                              label={"Baby length (in)"}
                              message={errors.length?.message}
                              fieldName={"lengthIn"}
                              type="number"
                              style={{ flex: "1 1 0" }}
                            />
                          </Col>
                          <Col span={8}>
                            <BJSelectFormItem
                              size="large"
                              control={control}
                              error={!!errors.lengthUnit}
                              label={`Force Display Unit (${measurementSystem.lengthUnits.join(
                                "/"
                              )})`}
                              message={errors.lengthUnit?.message}
                              defaultValue={""}
                              optionsList={lengthUnitOptions}
                              style={{ minWidth: "1rem" }}
                              fieldName={"lengthUnit"}
                              includeEmpty
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row style={{ flexGrow: 1 }} gutter={16}>
                      {measurementSystem.name === MeasurementSystem.Metric ? (
                        <>
                          <Col span={12}>
                            <BJInputNumberFormItem
                              control={control}
                              error={!!errors.length}
                              label={"Baby weight"}
                              message={errors.length?.message}
                              fieldName={"weight"}
                              type="number"
                            />
                          </Col>
                          <Col span={12}>
                            <BJSelectFormItem
                              size="large"
                              control={control}
                              error={!!errors.weightUnit}
                              label={`Unit(${measurementSystem.weightUnits.join(
                                "/"
                              )})`}
                              message={errors.weightUnit?.message}
                              defaultValue={
                                milestone?.weightUnit ??
                                measurementSystem.weightUnits[0]
                              }
                              optionsList={weightUnitOptions}
                              style={{ minWidth: "1rem" }}
                              fieldName={"weightUnit"}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={8}>
                            <BJInputNumberFormItem
                              control={control}
                              error={!!errors.weightLb}
                              label={"Baby weight (lb)"}
                              message={errors.weightLb?.message}
                              fieldName={"weightLb"}
                              type="number"
                            />
                          </Col>
                          <Col span={8}>
                            <BJInputNumberFormItem
                              control={control}
                              error={!!errors.weightOz}
                              label={"Baby weight (oz)"}
                              message={errors.weightOz?.message}
                              fieldName={"weightOz"}
                              type="number"
                            />
                          </Col>
                          <Col span={8}>
                            <BJSelectFormItem
                              size="large"
                              control={control}
                              error={!!errors.weightUnit}
                              label={`Force Display Unit (${measurementSystem.weightUnits.join(
                                "/"
                              )})`}
                              message={errors.weightUnit?.message}
                              defaultValue={""}
                              optionsList={weightUnitOptions}
                              style={{ minWidth: "1rem" }}
                              fieldName={"weightUnit"}
                              includeEmpty
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                    <Divider />
                  </>
                )}
                <div className="d-flex justify-content-between align-items-center mb-2 ">
                  <p className="m-0">Days</p>
                  <div>
                    <BJButton
                      size="small"
                      onClick={() => {
                        setShowAddDay(true);
                      }}
                      disabled={
                        days.length >= 7 ||
                        maximumWeek < period ||
                        period < minimumWeek
                      }
                    >
                      Add
                    </BJButton>
                  </div>
                </div>
                <List
                  itemLayout="horizontal"
                  dataSource={days.sort((a, b) => b.day - a.day)}
                  renderItem={item => (
                    <StyledListItem onClick={() => setSelectedDay(item)}>
                      <List.Item.Meta
                        avatar={<Avatar src={item.imageUrl} />}
                        title={
                          <Typography.Paragraph>
                            {item.day} Day: {item.text}
                          </Typography.Paragraph>
                        }
                        description={item.id}
                      />
                    </StyledListItem>
                  )}
                />
              </>
            </Col>
          </Row>
        )}
      </FormEdit>
      <DayModal
        show={showAddDay || !!selectedDay}
        existingDays={days.map(({ day }) => day)}
        onHide={() => {
          setShowAddDay(false);
          setSelectedDay(null);
        }}
        week={period}
        onAdd={onAddDay}
        milestoneDay={selectedDay}
        onUpdate={onUpdateDay}
        onDelete={onDeleteDay}
        isMonth={periodType !== defaultPeriod}
        onUploadImage={milestonesService.uploadMilestoneDayImage}
      />
    </>
  );
};

const StyledListItem = styled(List.Item)`
  cursor: pointer;
  padding: 1rem;
  border-radius: 1rem;
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`;
