import { useCallback, useEffect, useMemo, useState } from "react";
import { useCountry, useOffers } from "../context";
import { CustomChecklistOffersService } from "../services";
import { CheckListType } from "../utils";

export const useCustomChecklistOffers = () => {
  const { offers } = useOffers();
  const [loading, setLoading] = useState(false);
  const countryCode = useCountry()?.currentCountry?.abb;
  const [checklistOffers, setChecklistOffers] = useState<
    CustomChecklistOffer[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchOffers = async () => {
      setLoading(true);
      try {
        const offers = await CustomChecklistOffersService.get(countryCode);
        setChecklistOffers(offers);
      } catch (error) {
        console.error("Error fetching checklist offers", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, [countryCode]);

  const addOffer = useCallback(
    async ({
      offers,
      type,
    }: {
      offers: CustomChecklistOffer[];
      type: CheckListType;
    }) => {
      const updatedChecklistOffers = [
        ...checklistOffers.filter(o => o.type !== type),
        ...offers,
      ];

      try {
        await CustomChecklistOffersService.update(
          countryCode,
          updatedChecklistOffers
        );
        setChecklistOffers(updatedChecklistOffers);
      } catch (error) {
        console.error("Error adding checklist offer", error);
        setError(error as Error);
      }
    },
    [checklistOffers, countryCode]
  );

  const deleteOffer = useCallback(
    async ({ id, type }) => {
      const updatedChecklistOffers = checklistOffers.filter(
        o => !(o.id === id && o.type === type)
      );

      try {
        await CustomChecklistOffersService.update(
          countryCode,
          updatedChecklistOffers
        );
        setChecklistOffers(updatedChecklistOffers);
      } catch (error) {
        console.error("Error deleting checklist offer", error);
        setError(error as Error);
      }
    },
    [checklistOffers, countryCode]
  );

  const updateOffer = useCallback(
    async (offers: CustomChecklistOffer[]) => {
      try {
        setChecklistOffers(offers);
        await CustomChecklistOffersService.update(countryCode, offers);
      } catch (error) {
        console.error("Error deleting checklist offer", error);
        setError(error as Error);
      }
    },
    [countryCode]
  );

  const customChecklistOffers = useMemo(() => {
    const pregnancy = checklistOffers
      .filter(o => o.type === CheckListType.pregnancy)
      .map(o => ({
        ...o,
        offer: offers.find(offer => offer.id === o.id),
      }))
      .reduce((p, c) => [...p, c], [])
      .sort((a, b) => a.sortOrder - b.sortOrder);

    const infant = checklistOffers
      .filter(o => o.type === CheckListType.infant)
      .map(o => ({
        ...o,
        offer: offers.find(offer => offer.id === o.id),
      }))
      .reduce((p, c) => [...p, c], [])
      .sort((a, b) => a.sortOrder - b.sortOrder);

    const children = checklistOffers
      .filter(o => o.type === CheckListType.children)
      .map(o => ({
        ...o,
        offer: offers.find(offer => offer.id === o.id),
      }))
      .reduce((p, c) => [...p, c], [])
      .sort((a, b) => a.sortOrder - b.sortOrder);

    return [
      {
        title: "Pregnancy",
        offers: pregnancy,
        type: CheckListType.pregnancy,
      },
      {
        title: "Child 0-1 years",
        offers: infant,
        type: CheckListType.infant,
      },
      {
        title: "Child 1-5 years",
        offers: children,
        type: CheckListType.children,
      },
    ];
  }, [checklistOffers, offers]);

  return {
    customChecklistOffers,
    addOffer,
    deleteOffer,
    updateOffer,
    error,
    loading,
  };
};
