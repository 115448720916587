import { Col, Row, Typography, Form, Divider, Tabs, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormModal } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AppNavigationType,
  AspectRatio,
  dayValidationMaxValue,
  dayValidationMinValue,
  formValidationError,
  TipsType,
} from "../../utils";
import { useCountry, useTipsNew } from "../../context";
import { BJInputFormItem } from "../../components/theme/molecules/formItems/BJFormInput";
import { BJMdFormItem } from "../../components/theme/molecules/formItems/BJFormMarkdown";
import { BJSelectFormItem } from "../../components/theme";
import { AutoComplete } from "antd";
import { DropAndCrop } from "../../components/DropAndCrop";
import { useFilteredList } from "../../hooks/useFilteredList";
import { BJSwitchFormItem } from "../../components/theme/molecules/formItems/BJFormSwitch";
import { BJDeeplinkFormInput } from "../../components/theme/molecules/formItems/BJDeeplinkFormInput";
import * as yup from "yup";
import {
  commonErrors,
  highlightsErrorMessages,
  tipsErrorMessages,
} from "../../language";
import { TranslationSection } from "../../components/TranslationSection";
import { SetValueFunction, TranslationsObject } from "../../types/translation";

const { TabPane } = Tabs;

interface Props {
  show: boolean;
  onHide: () => void;
  tip?: Tips;
  tipType: "pregnancy" | "child";
}

export enum TipsNavigationType {
  Content = "content",
  DeepLink = "deepLink",
}

type FormValues = {
  day: string;
  type: string;
  externalUrl: string;
  imageUrl: string;
  blurhash?: string;
  external: boolean;
  contentId: string;
  enableNavigationAction: boolean;
  deepLink: string;
  navigationType?: TipsNavigationType;
  translations: {
    [locale: string]: {
      tip: string;
      buttonText: string;
    };
  };
};

const tipsOptions = [
  {
    key: AppNavigationType.FAQ,
    value: AppNavigationType.FAQ,
    display: AppNavigationType.FAQ,
  },
  {
    key: AppNavigationType.ARTICLE,
    value: AppNavigationType.ARTICLE,
    display: AppNavigationType.ARTICLE,
  },
  {
    key: AppNavigationType.OFFER,
    value: AppNavigationType.OFFER,
    display: AppNavigationType.OFFER,
  },
  {
    key: AppNavigationType.EXTERNAL,
    value: AppNavigationType.EXTERNAL,
    display: AppNavigationType.EXTERNAL,
  },
];

const titleMaxLength = 30;
const buttonTextMaxLength = 20;
const { requiredError } = commonErrors;

export const TipsModal = ({ show: showModal, onHide, tip, tipType }: Props) => {
  const {
    pregnancyTips,
    childTips,
    loadingPregnancyTips,
    loadingChildTips,
    updateChildTips,
    updatePregnancyTips,
    uploadTipsImage,
  } = useTipsNew();
  const [contentType, setContentType] = useState<AppNavigationType>(
    tip?.type as AppNavigationType
  );
  const { filterList, loading: loadingFilteredList } =
    useFilteredList(contentType);
  const { currentCountry, primaryLocale } = useCountry();

  const schema = useMemo(() => {
    return yup.object().shape({
      day: yup
        .number()
        .typeError("Day: Please specify a number")
        .max(dayValidationMaxValue, tipsErrorMessages.recordMaxMin)
        .min(dayValidationMinValue, tipsErrorMessages.recordMaxMin)
        .required(`Day: ${requiredError}`)
        .test("unique", "Day already exists", function (value) {
          if (tip?.day && Number(tip.day) === value) {
            return true;
          }

          return tipType == "pregnancy"
            ? !pregnancyTips.some(tip => Number(tip.day) === value)
            : !childTips.some(tip => Number(tip.day) === value);
        }),

      contentId: yup
        .string()
        .nullable()
        .test(
          "record valid",
          `Title/Question: ${requiredError}`,
          function (value: string): boolean {
            return (
              !this.parent.enableNavigationAction ||
              this.parent.navigationType === TipsNavigationType.DeepLink ||
              this.parent.type === AppNavigationType.EXTERNAL ||
              !!value?.length
            );
          }
        ),
      type: yup
        .string()
        .nullable()
        .test(
          "record valid",
          `Type: ${requiredError}`,
          function (value: string): boolean {
            return (
              !this.parent.enableNavigationAction ||
              this.parent.navigationType === TipsNavigationType.DeepLink ||
              this.parent.type === AppNavigationType.EXTERNAL ||
              !!value?.length
            );
          }
        ),
      externalUrl: yup
        .string()
        .url()
        .nullable()
        .test(
          "record valid",
          `External URL: ${requiredError}`,
          function (value: string): boolean {
            return (
              !this.parent.enableNavigationAction ||
              this.parent.navigationType === TipsNavigationType.DeepLink ||
              this.parent.type !== AppNavigationType.EXTERNAL ||
              !!value?.length
            );
          }
        ),
      deepLink: yup
        .string()
        .url()
        .nullable()
        .test(
          "record valid",
          `Deep link: ${requiredError}`,
          function (value: string): boolean {
            return (
              !this.parent.enableNavigationAction ||
              this.parent.navigationType === TipsNavigationType.Content ||
              this.parent.type?.length ||
              !!value?.length
            );
          }
        ),
      translations: yup.object().shape(
        currentCountry?.locales.reduce((acc, item) => {
          acc[item.key] = yup.object().shape({
            tip: yup
              .string()
              .required(
                `Tip (${String(item.key).toUpperCase()}): ${requiredError}`
              ),
            buttonText: yup
              .string()
              .nullable()
              .max(
                titleMaxLength,
                highlightsErrorMessages.recordMax(buttonTextMaxLength)
              )
              .test(
                "record valid",
                `Button text (${String(
                  item.key
                ).toUpperCase()}): ${requiredError}`,
                function (value: string): boolean {
                  return (
                    !this.parent.enableNavigationAction ||
                    (this.parent.enableNavigationAction && !!value)
                  );
                }
              ),
          });
          return acc;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, {} as any)
      ),
    });
  }, [currentCountry?.locales, childTips, pregnancyTips, tip?.day, tipType]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      navigationType: tip?.deepLink
        ? TipsNavigationType.DeepLink
        : TipsNavigationType.Content,
    },
  });

  useEffect(() => {
    if (tipType === "child") {
      if (loadingChildTips || !tip || loadingFilteredList) {
        return;
      }
    }
    if (tipType === "pregnancy") {
      if (loadingPregnancyTips || !tip || loadingFilteredList) {
        return;
      }
    }

    reset({
      ...tip,
      enableNavigationAction: tip?.enableNavigationAction || false,
      day: tip?.day ?? null,
      external: !tip?.contentId,
      deepLink: tip?.deepLink ?? "",
      contentId: tip?.contentId ?? "",
    });
  }, [
    loadingChildTips,
    loadingFilteredList,
    loadingPregnancyTips,
    reset,
    tip,
    tipType,
  ]);

  const handleBannerImageUrl = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };

  const handleBannerBlurhash = (url: string | null) => {
    setValue("blurhash", url, { shouldDirty: true });
  };

  const updateTipByType = async (tips: Tips[]) => {
    // convert this to a switch case when there are more than two types
    if (tipType === "pregnancy") {
      updatePregnancyTips(tips);
    } else {
      updateChildTips(tips);
    }
  };

  const { enableNavigationAction, type, navigationType, translations } =
    watch();

  const getTipsByType = () =>
    (tipType === TipsType.Child ? childTips : pregnancyTips) || [];

  const onSubmit = async (data: FormValues) => {
    const translations: Tips["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        tip: value.tip,
        buttonText: value.buttonText,
      };
    }

    const updatedTips: Tips = {
      day: data.day,
      enableNavigationAction: data.enableNavigationAction,
      type:
        data.navigationType !== TipsNavigationType.DeepLink ? data.type : "",
      contentId:
        data.navigationType !== TipsNavigationType.DeepLink
          ? data.contentId
          : "",
      imageUrl: data.imageUrl,
      blurhash: data.blurhash,
      externalUrl: data.externalUrl,
      deepLink:
        data.navigationType === TipsNavigationType.DeepLink
          ? data.deepLink
          : "",
      translations,
    };

    if (tip) {
      const mappedTips: Tips[] = getTipsByType().map(x => {
        if (x.day === tip.day) {
          return updatedTips;
        }
        return x;
      });
      await updateTipByType(mappedTips);
    } else {
      const mapped = [...getTipsByType(), updatedTips];
      await updateTipByType(mapped);
    }
  };

  const copyToTipsDeepLink = (link: string) => {
    setValue("deepLink", link);
  };

  const setNavTab = (key: string) => {
    setValue("navigationType", key as TipsNavigationType);
  };

  const defaultContentIdValue =
    filterList?.find(x => x.key === tip?.contentId)?.value ?? "";

  const navigationTab = () => {
    return (
      <Tabs defaultActiveKey={navigationType} onChange={setNavTab}>
        <TabPane tab="Content" key={TipsNavigationType.Content}>
          <Row gutter={12}>
            <Col span={12}>
              <BJSelectFormItem
                size="large"
                disabled={!enableNavigationAction}
                control={control}
                required={true}
                error={!!errors.type}
                label={"Type"}
                extra="select type"
                message={errors.type?.message}
                defaultValue={tip?.type}
                handleChange={(value: string) => {
                  setContentType(value as AppNavigationType);
                  setValue("contentId", null, {
                    shouldDirty: true,
                  });
                }}
                optionsList={tipsOptions}
                fieldName={"type"}
              />
            </Col>
            <Col span={12}>
              {type !== AppNavigationType.EXTERNAL ? (
                <Form.Item
                  label="Title/ Question"
                  key="content"
                  required={true}
                  validateStatus={errors.contentId && "error"}
                  {...(errors.contentId
                    ? {
                        help: (
                          <Typography.Paragraph type="danger">
                            {errors.contentId.message}
                          </Typography.Paragraph>
                        ),
                      }
                    : {})}
                  extra={
                    <Typography.Paragraph type="warning">
                      {"select title/ question"}
                    </Typography.Paragraph>
                  }
                >
                  <Controller
                    control={control}
                    name="contentId"
                    render={({ field: { onChange } }) => (
                      <AutoComplete
                        size="large"
                        allowClear
                        disabled={!enableNavigationAction}
                        notFoundContent={"not found"}
                        backfill
                        key={defaultContentIdValue}
                        defaultValue={defaultContentIdValue}
                        options={filterList}
                        placeholder={`type title name to select `}
                        filterOption={(inputValue, option) => {
                          return option?.value
                            ? option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            : false;
                        }}
                        onSelect={(value, option) => {
                          onChange(option!.key);
                        }}
                        onChange={value => {
                          const x = filterList?.find(x => x.value === value);
                          onChange(x?.key);
                        }}
                      />
                    )}
                  />
                </Form.Item>
              ) : (
                <BJInputFormItem
                  control={control}
                  error={!!errors.externalUrl}
                  label={"External url"}
                  extra="enter external url"
                  message={errors.externalUrl?.message}
                  fieldName={"externalUrl"}
                  disabled={!enableNavigationAction}
                  required={true}
                />
              )}
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={
            <Tooltip
              placement="topLeft"
              title={
                "This feature works for App version 2.17.0 and above ONLY "
              }
            >
              <Typography.Paragraph>Deep Link</Typography.Paragraph>{" "}
            </Tooltip>
          }
          key={TipsNavigationType.DeepLink}
        >
          <BJDeeplinkFormInput
            control={control}
            error={!!errors.deepLink}
            label={"Deep link"}
            message={errors.deepLink?.message}
            required
            fieldName={"deepLink"}
            title="Daily doola"
            copyToNotificationDeepLink={copyToTipsDeepLink}
          />
        </TabPane>
      </Tabs>
    );
  };

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={showModal}
      enableDelete={false}
      size="xl"
      modalTitle={tip?.day ?? "Add tip"}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      localeSupported
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errors={errors as any}
    >
      {locale => (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Row>
                <Col span={12}>
                  <BJInputFormItem
                    control={control}
                    error={!!errors.day}
                    label={"Day"}
                    message={errors.day?.message}
                    required={true}
                    autoFocus
                    fieldName={"day"}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <BJMdFormItem
                    control={control}
                    error={!!errors?.translations?.[locale?.key]?.tip}
                    key={`translations.${locale?.key}.tip`}
                    label={`Tip (${locale?.label})`}
                    message={errors.translations?.[locale?.key]?.tip?.message}
                    required={true}
                    fieldName={`translations.${locale?.key}.tip`}
                  />
                  <TranslationSection
                    locale={locale}
                    primaryLocale={primaryLocale}
                    translations={translations as TranslationsObject}
                    setValue={setValue as SetValueFunction}
                    availableFields={["tip", "buttonText"]}
                    requiredFields={["tip"]}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Divider orientation="left"> Mobile button content</Divider>
                <Col span={24}>
                  <BJSwitchFormItem
                    control={control}
                    label="Display navigation action  on mobile"
                    fieldName={"enableNavigationAction"}
                    extra="If disabled, tip button will not appear on mobile, but record will be saved as draft"
                  />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <BJInputFormItem
                    disabled={!enableNavigationAction}
                    control={control}
                    error={!!errors?.translations?.[locale?.key]?.buttonText}
                    label={`Text (${locale?.label})`}
                    message={
                      errors?.translations?.[locale?.key]?.buttonText?.message
                    }
                    key={`translations.${locale?.key}.buttonText`}
                    fieldName={`translations.${locale?.key}.buttonText`}
                  />
                </Col>
              </Row>
              <Col span={24}>
                <Form.Item
                  label="Image"
                  validateStatus={errors?.imageUrl?.message && "error"}
                  {...(errors?.imageUrl?.message
                    ? {
                        help: (
                          <Typography.Paragraph type="danger">
                            {errors?.imageUrl?.message}
                          </Typography.Paragraph>
                        ),
                      }
                    : undefined)}
                >
                  <Controller
                    control={control}
                    name="imageUrl"
                    render={() => (
                      <DropAndCrop
                        disabled={!enableNavigationAction}
                        title="In app image"
                        setUploadUrl={handleBannerImageUrl}
                        uploadImage={uploadTipsImage}
                        initialUrl={tip?.imageUrl}
                        lockedRatio={AspectRatio.SixteenToNine}
                        setBlurhash={handleBannerBlurhash}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
              <Divider orientation="left">Navigation Content</Divider>
              {navigationTab()}
            </Col>
          </Row>
        </>
      )}
    </FormModal>
  );
};
