import { useState, useEffect } from "react";
import { DoulaServices } from "../services/DoulaServices";
import { logException } from "../utils/exceptionLogger";
import { CategoryData } from "../services/DoulaServices";
/**
 * React hook for managing Daily Doula questions
 * @returns Object containing data, loading state, error, and refetch function
 */
interface UseDoulaQuestionsResult {
  data: Record<string, CategoryData> | null;
  loading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

export const useDoulaQuestions = (): UseDoulaQuestionsResult => {
  const [data, setData] = useState<Record<string, CategoryData> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await DoulaServices.getDailyDoulaQuestions();
      setData(result);
      setError(null);
    } catch (err) {
      setError(err as Error);
      logException(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error, refetch: fetchData };
};
