import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Row, Col, Typography, Form, DatePicker } from "antd";
import { useBanner, useCountry, useSponsors } from "../../context";
import {
  AspectRatio,
  BannerDisplayableSegment,
  formatDateStringToTimeStamp,
  formValidationError,
  getMobileBannerSegments,
  isEqualArrays,
  NUM_OF_MONTHS,
  NUM_OF_WEEKS,
  NUM_OF_WEEKS_FOR_CHILD,
  ScreenInMobile,
} from "../../utils";
import { FormEdit, FormEditType } from "../../components";
import { DropAndCrop } from "../../components/DropAndCrop";
import { commonErrors } from "../../language";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  BJInputFormItem,
  BJSelectFormItem,
  BJSelectFormItemLevel,
  BJSwitchFormItem,
} from "../../components/theme";
import moment from "moment";
import { banners, cms } from "../../routes/routeConsts";
import { ScreenInMobileToReadable } from "../../helper/enum";
import { BJSlider } from "../../components/theme/molecules/BJSlider";
import { BJDeeplinkFormInput } from "../../components/theme/molecules/formItems/BJDeeplinkFormInput";
import {
  BJAudienceMultiple,
  BJAudienceMultipleSchema,
  BJAudienceMultipleValues,
} from "../../components/theme/components/BJAudienceMultiple";

interface FormValues extends Omit<BJAudienceMultipleValues, "audiences"> {
  internalName: string;
  isActive: boolean;
  deepLink: string;
  startEnd?: [any, any]; //design limitation for complex objects in react-hook forms...
  screens: ScreenInMobile[];
  userSegment: BannerDisplayableSegment;
  isMiniJourney: boolean;
  contentId?: string;
  segmentLoyalty?: number[];
  isCompetition: boolean;
  hideCountdown?: boolean;
  miniJourneySponsorName?: string;
  translations: {
    [locale: string]: {
      miniJourneyHeader?: string | null;
      miniJourneyBody?: string | null;
      imageUrl?: string;
      blurhash?: string;
    };
  };
  level?: number;
}

const { requiredError2: requiredError } = commonErrors;
const { RangePicker } = DatePicker;

export const BannerPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { banner, loading: loadingBanners, bannersService } = useBanner(id);
  const { sponsors } = useSponsors();
  const [monthRange, setMonthRange] = useState<[number, number]>([0, 12]);
  const [weekRange, setWeekRange] = useState<[number, number]>([
    35,
    NUM_OF_WEEKS,
  ]);

  const { currentCountry, primaryLocale } = useCountry();

  const schema = yup.object().shape({
    internalName: yup.string().required(`Internal Name: ${requiredError}`),
    deepLink: yup
      .string()
      .required(`Deeplink: ${requiredError}`)
      .url(`Deeplink: ${commonErrors.urlValidationError}`),
    screens: yup
      .array()
      .min(1, requiredError)
      .required(`Screens: ${requiredError}`),
    miniJourneySponsorName: yup.string(),
    segmentLoyalty: yup.array(),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          miniJourneyHeader: yup.string().when("isMiniJourney", {
            is: true,
            then: yup
              .string()
              .required(
                `Mini Journey Header (${item.key.toUpperCase()}): ${requiredError})`
              ),
            otherwise: yup.string().nullable(),
          }),
          miniJourneyBody: yup.string().when("isMiniJourney", {
            is: true,
            then: yup
              .string()
              .required(
                `Mini Journey Body (${item.key.toUpperCase()}): ${requiredError})`
              ),
            otherwise: yup.string().nullable(),
          }),
          imageUrl: yup
            .string()
            .required(`Image Url (${item.key.toUpperCase()}): ${requiredError}`)
            .url(
              `Image Url (${item.key.toUpperCase()}): ${
                commonErrors.urlValidationError
              }`
            )
            .nullable(),
        });
        return acc;
      }, {} as any)
    ),
    ...BJAudienceMultipleSchema(false),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      translations: {},
    },
  });

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    getValues,
  } = methods;

  useEffect(() => {
    if (loadingBanners || banner === null) {
      reset({
        translations: currentCountry?.locales?.reduce((acc, item) => {
          acc[item.key] = {
            miniJourneyHeader: "",
            miniJourneyBody: "",
            imageUrl: "",
          };
          return acc;
        }, {} as Record<string, { miniJourneyHeader: string; miniJourneyBody: string; imageUrl: string }>),
      });

      return;
    }
    const startTime = banner.startTime ? moment(banner.startTime) : null;
    const endTime = banner.endTime ? moment(banner.endTime) : null;
    const screens: ScreenInMobile[] = banner.screens.map(
      ({ screen }) => screen
    );
    const translations: Banner["translations"] = {};
    for (const { key } of currentCountry?.locales) {
      translations[key] = {
        miniJourneyHeader: banner?.translations?.[key]?.miniJourneyHeader ?? "",
        miniJourneyBody: banner?.translations?.[key]?.miniJourneyBody ?? "",
        imageUrl: banner?.translations?.[key]?.imageUrl ?? "",
      };
    }
    reset({
      ...banner,
      userSegment:
        banner?.targetSegment?.segment ?? BannerDisplayableSegment.allUsers,
      startEnd: [startTime, endTime],
      screens,
      translations,
    });
    setWeekRange(x => banner?.targetSegment?.extras?.weeksNumberRange ?? x);
    setMonthRange(x => banner?.targetSegment?.extras?.monthsNumberRange ?? x);
  }, [banner, reset, loadingBanners, currentCountry?.locales]);

  const onSubmit = async (data: FormValues) => {
    const startTime = data.startEnd?.[0]
      ? formatDateStringToTimeStamp(
          data.startEnd?.[0].seconds(0).toString(),
          false
        )
      : null;
    const endTime = data.startEnd?.[1]
      ? formatDateStringToTimeStamp(
          data.startEnd?.[1].seconds(0).toString(),
          false
        )
      : null;
    const screens = data.screens.map(value => ({ screen: value, position: 0 }));

    const translations: Banner["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        miniJourneyHeader: value.miniJourneyHeader,
        miniJourneyBody: value.miniJourneyBody,
        imageUrl: value.imageUrl,
        blurhash: value.blurhash,
      };
    }
    const MJIdFromDeepLink = data?.deepLink
      .split("/")
      .slice(-1)[0]
      .split("?")[0];

    const updateData: Banner = {
      internalName: data.internalName,
      isActive: data.isActive,
      startTime: startTime ?? null,
      endTime: endTime ?? null,
      deepLink: data.deepLink,
      screens,
      targetSegment: {
        segment: data?.userSegment,
        extras: {
          weeksNumberRange:
            data?.userSegment ===
              BannerDisplayableSegment.pregnanciesWithInWeeksRange ||
            data?.userSegment ===
              BannerDisplayableSegment.childrenWithInWeeksRange
              ? weekRange
              : undefined,
          monthsNumberRange:
            data?.userSegment ===
            BannerDisplayableSegment.childrenWithInMonthsRange
              ? monthRange
              : undefined,
        },
      },
      isMiniJourney: data.isMiniJourney,
      contentId: data.isMiniJourney ? data.contentId ?? MJIdFromDeepLink : null,
      isCompetition: data.isCompetition,
      segmentLoyalty:
        data.segmentLoyalty && data.segmentLoyalty.length > 0
          ? data.segmentLoyalty
          : [0],
      miniJourneySponsorName: data.miniJourneySponsorName,
      translations,
      hideCountdown: data.hideCountdown,
      // keep this until we force update 2.35.0 or latest
      imageUrl: translations[primaryLocale.key]?.imageUrl ?? "",
      level: data.level,
      cities: data.cities,
    };

    if (banner) {
      await bannersService.update(banner.id, updateData);
    } else {
      await bannersService.create(updateData);
    }
    return navigate(`../`);
  };

  const onRemove = async () => {
    if (banner) {
      await bannersService.deleteBanner(banner.id);
      return navigate(`${cms}/${banners}`);
    }
  };
  const handleBannerImageUrl = ({
    url,
    locale,
  }: {
    url: string | null;
    locale: string;
  }) => {
    const temp = { ...getValues("translations") };
    temp[locale] = {
      ...temp[locale],
      imageUrl: url,
    };
    setValue("translations", temp, { shouldDirty: true });
  };

  const handleBannerBlurhash = ({
    url,
    locale,
  }: {
    url: string | null;
    locale: string;
  }) => {
    const temp = { ...getValues("translations") };
    temp[locale] = {
      ...temp[locale],
      blurhash: url,
    };
    setValue("translations", temp, { shouldDirty: true });
  };

  const copyToNotificationDeepLink = (link: string) => {
    setValue("deepLink", link);
  };

  const copyContentId = (contentId: string) => {
    setValue("contentId", contentId);
  };

  const isDirty =
    !!Object.keys(dirtyFields).length ||
    !isEqualArrays(
      banner?.targetSegment?.extras?.monthsNumberRange ?? [],
      monthRange
    ) ||
    !isEqualArrays(
      banner?.targetSegment?.extras?.weeksNumberRange ?? [],
      weekRange
    );

  const bannerTitle = watch("internalName");
  const { userSegment } = watch();
  const isMiniJourney = watch("isMiniJourney");

  return (
    <FormProvider {...methods}>
      <FormEdit
        backRoutePath={`${cms}/${banners}`}
        onRemove={onRemove}
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={isDirty}
        title={banner ? banner?.internalName : "New Banner"}
        id={banner?.id}
        editType={banner?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loadingBanners}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        localeSupported
        recordIdentifier={bannerTitle}
        errors={errors as any}
      >
        {locale => (
          <Row gutter={{ md: 20 }}>
            <Col md={24} lg={12}>
              <BJInputFormItem
                control={control}
                error={!!errors?.internalName}
                label={"Internal name"}
                message={errors.internalName?.message}
                required={true}
                autoFocus
                fieldName={"internalName"}
              />
              <Form.Item
                required
                label={`Banner Image ${
                  locale.label ? `(${locale.label})` : ""
                }`}
                extra=""
                validateStatus={
                  errors?.translations &&
                  errors?.translations?.[locale.key]?.imageUrl &&
                  "error"
                }
                help={
                  errors?.translations &&
                  errors.translations[locale?.key]?.imageUrl && (
                    <Typography.Paragraph type="danger">
                      {errors.translations[locale.key].imageUrl.message}
                    </Typography.Paragraph>
                  )
                }
                key={`translations.${locale.key}.imageUrl`}
              >
                <DropAndCrop
                  extra={`Please upload a banner image with size of 1400 × 700`}
                  allowNaturalImageUpload={true}
                  title={`Banner Image ${
                    locale.label ? `(${locale.label})` : ""
                  }`}
                  initialUrl={
                    watch("translations")?.[locale.key]?.imageUrl ?? ""
                  }
                  setUploadUrl={(url: string) =>
                    handleBannerImageUrl({
                      url,
                      locale: locale.key,
                    })
                  }
                  setBlurhash={(url: string) =>
                    handleBannerBlurhash({
                      url,
                      locale: locale.key,
                    })
                  }
                  uploadImage={bannersService.uploadBannerImage}
                  lockedRatio={AspectRatio.TwoToOne}
                  defaultCropBoxWidth={800}
                  defaultCropBoxHeight={400}
                  croppable={true}
                  key={`translations.${locale.key}.imageUrl`}
                />
              </Form.Item>

              {isMiniJourney && (
                <>
                  <BJInputFormItem
                    control={control}
                    error={
                      !!errors?.translations?.[locale.key]?.miniJourneyHeader
                    }
                    label={`Mini Journey Header ${
                      locale.label ? `(${locale.label})` : ""
                    }`}
                    message={
                      errors.translations?.[locale.key]?.miniJourneyHeader
                        ?.message
                    }
                    required={true}
                    autoFocus
                    fieldName={`translations.${locale.key}.miniJourneyHeader`}
                    key={`translations.${locale.key}.miniJourneyHeader`}
                  />
                  <BJInputFormItem
                    control={control}
                    error={
                      !!errors?.translations?.[locale.key]?.miniJourneyBody
                    }
                    label={`Mini Journey Body ${
                      locale.label ? `(${locale.label})` : ""
                    }`}
                    message={
                      errors.translations?.[locale.key]?.miniJourneyBody
                        ?.message
                    }
                    required={true}
                    autoFocus
                    fieldName={`translations.${locale.key}.miniJourneyBody`}
                    key={`translations.${locale.key}.miniJourneyBody`}
                  />
                  <BJSelectFormItem
                    disabled={false}
                    size="large"
                    control={control}
                    error={!!errors.miniJourneySponsorName}
                    label={"Sponsor"}
                    message={errors.miniJourneySponsorName?.message}
                    optionsList={[
                      {
                        value: "",
                        key: "",
                        display: "-",
                      },
                      ...sponsors.map(sponsor => ({
                        value: sponsor.name,
                        key: sponsor.id,
                        display: sponsor.name,
                      })),
                    ]}
                    fieldName={"miniJourneySponsorName"}
                  />
                </>
              )}
              <BJDeeplinkFormInput
                control={control}
                error={!!errors.deepLink}
                label={"Deep link"}
                message={errors.deepLink?.message}
                required
                fieldName={"deepLink"}
                title="Banner"
                copyToNotificationDeepLink={copyToNotificationDeepLink}
                copyContentId={copyContentId}
              />
            </Col>
            <Col md={24} lg={12}>
              <BJSelectFormItem
                defaultValue={[ScreenInMobile.Content]}
                control={control}
                error={!!errors.screens}
                label={
                  isMiniJourney
                    ? "Screen (Won't be displayed on Content screen)"
                    : "Screen"
                }
                message={errors.screens && (errors.screens as any).message}
                required={true}
                size={"large"}
                optionsList={[
                  {
                    value: ScreenInMobile.Content as string,
                    key: ScreenInMobile.Content as string,
                    display: ScreenInMobileToReadable(ScreenInMobile.Content),
                  },
                  {
                    value: ScreenInMobile.Child as string,
                    key: ScreenInMobile.Child as string,
                    display: ScreenInMobileToReadable(ScreenInMobile.Child),
                  },
                  {
                    value: ScreenInMobile.Pregnancy as string,
                    key: ScreenInMobile.Pregnancy as string,
                    display: ScreenInMobileToReadable(ScreenInMobile.Pregnancy),
                  },
                ]}
                fieldName={"screens"}
                multiple={true}
              />

              <BJSelectFormItemLevel
                control={control}
                fieldName={"level"}
                error={!!errors.level?.message}
                message={errors.level?.message}
              />

              <Form.Item label="Start | End" key="active">
                <Controller
                  name="startEnd"
                  control={control}
                  defaultValue={[
                    moment(Date.now()),
                    moment(Date.now()).add(1, "week"),
                  ]}
                  render={({ field: { onChange, value } }) => (
                    <RangePicker
                      format={"YYYY-MM-DD HH:mm"}
                      defaultValue={[moment(Date.now()), moment(Date.now())]}
                      showTime
                      value={value}
                      onChange={onChange}
                      size={"large"}
                    />
                  )}
                />
              </Form.Item>
              <BJSwitchFormItem
                key={"minijourney"}
                label={"Mini Journey"}
                fieldName={"isMiniJourney"}
                control={control}
              />
              <BJSwitchFormItem
                key={"competition"}
                label={"Competition"}
                fieldName={"isCompetition"}
                control={control}
              />
              <BJSwitchFormItem
                label={"Active"}
                fieldName={"isActive"}
                control={control}
              />
              {isMiniJourney && (
                <BJSwitchFormItem
                  label={"Hide countdown"}
                  fieldName={"hideCountdown"}
                  control={control}
                />
              )}
              <BJSelectFormItem
                disabled={false}
                defaultValue={BannerDisplayableSegment.allUsers}
                size="large"
                control={control}
                error={!!errors.userSegment}
                label={"User segment"}
                extra="Message will be published all users in selected segment"
                message={errors.userSegment?.message}
                optionsList={getMobileBannerSegments()}
                fieldName={"userSegment"}
              />
              {userSegment ===
                BannerDisplayableSegment.pregnanciesWithInWeeksRange && (
                <BJSlider
                  disabled={false}
                  onChange={(value: [number, number]) => {
                    setWeekRange(value);
                  }}
                  selectedRange={weekRange}
                  range
                  value={weekRange}
                  step={1}
                  defaultValue={[35, NUM_OF_WEEKS]}
                  max={NUM_OF_WEEKS}
                  min={1}
                  extra={
                    "If selected range from 1,3, users who`s pregnancy week is 1,2,3 will see the prioratized banner"
                  }
                  error={false}
                  message={""}
                  label={"select range"}
                />
              )}
              {userSegment ===
                BannerDisplayableSegment.childrenWithInMonthsRange && (
                <BJSlider
                  disabled={false}
                  range
                  extra={
                    "If selected range from 0,3, users who`s child is in month  0,1,2,3 will receive see the prioratized banner"
                  }
                  onChange={(value: [number, number]) => {
                    setMonthRange(value);
                  }}
                  step={1}
                  defaultValue={[1, 12]}
                  max={NUM_OF_MONTHS}
                  min={0}
                  value={monthRange}
                  selectedRange={monthRange}
                  error={false}
                  message={""}
                  label={"select range"}
                />
              )}
              {userSegment ===
                BannerDisplayableSegment.childrenWithInWeeksRange && (
                <BJSlider
                  disabled={false}
                  onChange={(value: [number, number]) => {
                    setWeekRange(value);
                  }}
                  selectedRange={weekRange}
                  range
                  value={weekRange}
                  step={1}
                  defaultValue={[1, NUM_OF_WEEKS_FOR_CHILD]}
                  max={NUM_OF_WEEKS_FOR_CHILD}
                  min={0}
                  extra="If selected range from 1,3, users who`s child is in week 1,2,3 will see the prioratized banner"
                  error={false}
                  message={""}
                  label={"select range"}
                />
              )}
              <BJSelectFormItem
                defaultValue={0}
                control={control}
                error={!!errors.segmentLoyalty}
                label={"Segment loyalty level"}
                message={
                  errors.segmentLoyalty &&
                  (errors.segmentLoyalty as any).message
                }
                size={"large"}
                optionsList={[
                  {
                    value: 0,
                    key: "0",
                    display: "All levels",
                  },
                  { value: 1, key: "1", display: "Newbie" },
                  { value: 2, key: "2", display: "Explorer" },
                  { value: 3, key: "3", display: "Genius" },
                  { value: 4, key: "4", display: "Legend" },
                ]}
                fieldName={"segmentLoyalty"}
                multiple={true}
                extra="Show for users with the selected level"
              />
              <BJAudienceMultiple showCities showAudience={false} />
            </Col>
          </Row>
        )}
      </FormEdit>
    </FormProvider>
  );
};
