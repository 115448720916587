import { useMemo, useState } from "react";
import { CheckListType } from "../../utils";
import { Col, List, Row, Space, Tabs, Typography } from "antd";
import styled from "styled-components";
import { useCustomChecklistOffers } from "../../hooks";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import { useCountry } from "../../context";
import { BiTrash } from "react-icons/bi";
import { SortableList } from "./SortableList";
import { AddOfferModal } from "../Popular/modal/AddOffersModal";

export const CustomChecklistOffersPage = () => {
  const { primaryLocale } = useCountry();
  const {
    customChecklistOffers: panes,
    loading,
    addOffer,
    deleteOffer,
    updateOffer,
  } = useCustomChecklistOffers();
  const [showAddOfferModal, setShowAddOfferModal] = useState(false);
  const [addType, setAddType] = useState<CheckListType>(null);
  const [sortList, setSortList] = useState(false);

  const onPressAddOffer = (type: CheckListType) => {
    setAddType(type);
    setShowAddOfferModal(true);
  };

  const onAddOffer = (offers: CommonContent[]) => {
    const offersData = offers.map((o, index) => ({
      id: o.id,
      type: addType,
      sortOrder: index,
    }));
    addOffer({ offers: offersData, type: addType });
    setShowAddOfferModal(false);
  };

  const onPressDeleteOffer = (
    id: CustomChecklistOffer["id"],
    type: CheckListType
  ) => {
    deleteOffer({ id, type });
  };

  const onUpdateSortOrder = (offers: CustomChecklistOffer[]) => {
    updateOffer(offers);
    setSortList(false);
  };

  const selectedContent = useMemo(() => {
    return (
      (panes.find(i => i.type === addType)?.offers as CommonContentV2[]) ?? []
    );
  }, [addType, panes]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <AddOfferModal
        show={showAddOfferModal}
        onHide={() => setShowAddOfferModal(false)}
        maxNoOfContentLimit={5}
        selectedContent={selectedContent}
        onAdd={onAddOffer}
        includePreviousContent={false}
      />

      <StyledTitle>Custom Checklist Offers</StyledTitle>
      <StyledSubtitle>
        Manage offers for custom checklists in Pregnancy, Child 0-1 years, and
        Child 1-5 years. Click on the category to view the offers.
      </StyledSubtitle>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <Tabs type="card" className="custom-offers">
            {panes.map((pane, index) => (
              <Tabs.TabPane tab={pane.title} key={`${pane.type}-${index}`}>
                <StyledTabContentContainer>
                  <Space>
                    {!sortList && (
                      <>
                        <BJButton
                          size="small"
                          buttonType={ButtonTypes.Save}
                          onClick={() => onPressAddOffer(pane.type)}
                        >
                          Add Offer
                        </BJButton>

                        <BJButton
                          size="small"
                          disabled={pane.offers.length <= 1}
                          buttonType={ButtonTypes.Primary}
                          onClick={() => setSortList(prev => !prev)}
                        >
                          Sort Offers
                        </BJButton>
                      </>
                    )}
                  </Space>

                  {sortList && (
                    <SortableList
                      initialItems={pane.offers}
                      onCancel={() => setSortList(false)}
                      onSave={onUpdateSortOrder}
                    />
                  )}
                  <Spacer size={20} />
                  {!sortList && pane?.offers && pane.offers.length > 0 && (
                    <List bordered>
                      {pane?.offers.map(o => {
                        return (
                          <List.Item key={o.id}>
                            <Typography key={`${o.id}`}>
                              {
                                o.offer?.translations?.[primaryLocale.key]
                                  ?.title
                              }
                            </Typography>
                            <BJButton
                              size="small"
                              buttonType={ButtonTypes.Delete}
                              onClick={() => onPressDeleteOffer(o.id, o.type)}
                            >
                              <BiTrash />
                            </BJButton>
                          </List.Item>
                        );
                      })}
                    </List>
                  )}
                </StyledTabContentContainer>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

const Spacer = styled.div<{ size: number }>`
  height: ${({ size = 1 }) => size}px;
`;

const StyledTabContentContainer = styled.div`
  border: 1px solid #f4f4f4;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
`;

const StyledTitle = styled.h1`
  margin-bottom: 5px;
`;

const StyledSubtitle = styled.p`
  margin-bottom: 40px;
`;
