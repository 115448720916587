import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useCountry, useOffers } from "../../context";
import { OffersService } from "../../services";
import { FormEdit, FormEditType } from "../../components/FormEdit";
import {
  BJSortableList,
  SortContentType,
} from "../../components/theme/molecules/BJSortableList";
import { arrayMove } from "../../utils/moveArrayItems";
import styled from "styled-components";
import { Row, Col, Divider, Button, message } from "antd";

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

const getOfferTitle = (offer: OfferV2, primaryLocale: Locale) => {
  return (
    offer?.translations?.[primaryLocale?.key]?.title ||
    offer?.title ||
    "No Title Available"
  );
};

export const OfferTopFive = () => {
  const { offers: allOffers, loading: offersLoading } = useOffers();
  const { handleSubmit } = useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [offers, setOffers] = useState<OfferV2[]>([]);
  const [selectedOffers, setSelectedOffers] = useState<OfferV2[]>([]);
  const { currentCountry } = useCountry();
  const { primaryLocale } = useCountry();
  const offersService = useMemo(
    () => new OffersService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setOffers(
      allOffers
        ?.sort((a: OfferV2, b: OfferV2) => a?.sortOrder - b?.sortOrder)
        .map((offer, index) => ({ ...offer, sortOrder: index }))
    );

    const fetchTopFiveOffers = async () => {
      try {
        const topFiveOffers = await offersService.getTopFiveOffers();
        const sortedTopFiveOffers = topFiveOffers?.sort(
          (a, b) => a?.sortOrder - b?.sortOrder
        );
        setSelectedOffers(sortedTopFiveOffers);
      } catch (err) {
        console.error("Error fetching top five offers:", err);
      }
    };

    fetchTopFiveOffers();
  }, [allOffers, offersService]);

  const saveOrder = async () => {
    try {
      if (selectedOffers.length === 0) {
        await offersService.clearTopFiveOffers();
      } else {
        await offersService.updateTopFiveOffers(
          selectedOffers.map((offer, index) => ({
            ...offer,
            sortOrder: index,
          }))
        );
      }
      setIsDirty(false);
    } catch (err) {
      message.error("Error updating top five offers.");
      console.error(err);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) return;
    const updatedOffers = arrayMove([...selectedOffers], oldIndex, newIndex);
    const reorderedOffers = updatedOffers.map((offer, index) => ({
      ...offer,
      sortOrder: index,
    }));

    setSelectedOffers(reorderedOffers);
    setIsDirty(true);
  };

  const handleSelectOffer = (offer: OfferV2) => {
    const isSelected = selectedOffers.some(o => o.id === offer.id);

    if (isSelected) {
      const updatedOffers = selectedOffers.filter(o => o.id !== offer.id);
      setSelectedOffers(updatedOffers);
    } else {
      if (selectedOffers.length >= 5) {
        message.warning("You can only select up to 5 offers.");
        return;
      }
      setSelectedOffers([...selectedOffers, offer]);
    }
    setIsDirty(true);
  };

  return (
    <StyledFullPageFormEdit
      enableSave={isDirty}
      title={"Order of content screen offers"}
      editType={FormEditType.VIEW}
      loading={offersLoading}
      onSubmit={handleSubmit(saveOrder)}
    >
      <Row gutter={16} justify="center" style={fullHeight}>
        <Col span={11} style={fullHeight}>
          <h3>Available Offers</h3>
          <StyledList>
            {offers.map(offer => {
              const isSelected = selectedOffers.some(o => o.id === offer.id);

              return (
                <OfferItem key={offer.id}>
                  <span>{getOfferTitle(offer, primaryLocale)}</span>
                  <StyledButton
                    isSelected={isSelected}
                    onClick={() => handleSelectOffer(offer)}
                  >
                    {isSelected ? "Selected" : "Select"}
                  </StyledButton>
                </OfferItem>
              );
            })}
          </StyledList>
        </Col>

        <StyledCenterCol span={1} style={fullHeight}>
          <StyledDivider type="vertical" />
        </StyledCenterCol>

        <Col span={11} style={fullHeight}>
          <h3>Selected Offers</h3>
          <BJSortableList
            items={selectedOffers.map(
              x =>
                ({
                  id: x.id,
                  title: getOfferTitle(x, primaryLocale),
                  noImage: true,
                } as SortContentType)
            )}
            onSortEnd={onSortEnd}
          />
        </Col>
      </Row>
    </StyledFullPageFormEdit>
  );
};

const fullHeight = {
  height: "100%",
};

const StyledFullPageFormEdit = styled(FormEdit)`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledList = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  padding: 8px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const OfferItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledButton = styled(Button)<{ isSelected: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: green;
    border-color: green;
    color: white;
  `}
`;

const StyledCenterCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDivider = styled(Divider)`
  height: 100%;
  border-color: #f0f0f0;
  border-width: 0.8px;
`;
