/**
 * AiTranslator.tsx
 *
 * A React component that provides the UI for initiating and handling translations.
 * This component renders translation buttons, handles the API call to the translation
 * service, and communicates the results back to the parent component.
 *
 * This is typically used through TranslationSection, which provides a higher-level
 * interface, but can be used directly for more customized translation needs.
 *
 * USAGE:
 * 1. Import in your component:
 *    ```
 *    import { AiTranslator } from '../../components/AiTranslator';
 *    ```
 *
 * 2. Add to your JSX, typically with data prepared via translationHelpers or useTranslationHandler:
 *    ```
 *    <AiTranslator
 *      locale={locale}
 *      data={dataArray}  // Array of strings to translate
 *      onSetValue={handleTranslationResult}
 *      toLanguage={locale.label}  // Target language display name
 *    />
 *    ```
 *
 * This component:
 * - Determines if translation is available based on languages and content
 * - Handles the API call to the translation service
 * - Processes the translation result
 * - Shows errors and loading states
 * - Provides buttons for translation and copying
 *
 * The translation API expects source and target languages as display names (e.g., "English", "Swedish")
 * rather than locale codes.
 */
import { Alert } from "antd";
import { CenteredSpinner } from "./CenteredSpinner";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { cloudFunctionNames } from "../utils";
import BJButton, { ButtonTypes } from "./theme/atoms/Button";
import styled from "styled-components";
import { useCountry } from "../context";
import { logException } from "../utils/exceptionLogger";
import { getLanguages, LanguageDefinition } from "../utils/commonUtils";
import {
  AiTranslatorProps,
  TranslationButton,
  TranslationResult,
} from "../types/translation";
import { Languages } from "../utils/commonEnums";

const languages: LanguageDefinition[] = getLanguages();

const supportedLanguages = languages.map(lang => lang.displayName);

const shortNameToDisplayName: Record<string, string> = {};
languages.forEach(lang => {
  shortNameToDisplayName[lang.shortName] = lang.displayName;
});

const countryToLanguageMap: Record<string, string> = {
  se:
    languages.find(lang => lang.shortName === Languages.swedish)?.displayName ||
    "Swedish",
  no:
    languages.find(lang => lang.shortName === Languages.norwegian)
      ?.displayName || "Norwegian",
  dk:
    languages.find(lang => lang.shortName === Languages.danish)?.displayName ||
    "Danish",
  de:
    languages.find(lang => lang.shortName === Languages.german)?.displayName ||
    "German",
};

const localeToLanguageMap: Record<string, string> = {};
languages.forEach(lang => {
  localeToLanguageMap[lang.shortName] = lang.displayName;
});

export const AiTranslator = ({
  locale,
  data,
  onSetValue,
  copyNonTranslation,
  showCopyButton = false,
  hideTranslation = false,
  fromLanguage,
  toLanguage,
}: AiTranslatorProps): JSX.Element => {
  const { currentCountry } = useCountry();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [noData, setNoData] = useState(false);

  const getRegionPrimaryLanguage = (): string => {
    if (fromLanguage) return fromLanguage;

    if (currentCountry?.abb && countryToLanguageMap[currentCountry.abb]) {
      return countryToLanguageMap[currentCountry.abb];
    }

    return "English";
  };

  const getCurrentEditLanguage = (): string | null => {
    if (toLanguage) return toLanguage;

    if (locale?.key && shortNameToDisplayName[locale.key]) {
      return shortNameToDisplayName[locale.key];
    }

    return null;
  };

  const hasPrimaryLanguageContent = (): boolean => {
    if (!data || !Array.isArray(data)) return false;

    return data.some(item => {
      if (item === undefined || item === null) return false;

      if (typeof item === "string") {
        return item.trim() !== "";
      }

      return true;
    });
  };

  const isTranslationAvailable = (): boolean => {
    if (locale?.primary === true) {
      return false;
    }

    if (!hasPrimaryLanguageContent()) {
      return false;
    }

    const primaryLanguage = getRegionPrimaryLanguage();
    const editLanguage = getCurrentEditLanguage();

    if (!editLanguage) {
      return false;
    }
    if (
      !supportedLanguages.includes(primaryLanguage) ||
      !supportedLanguages.includes(editLanguage)
    ) {
      return false;
    }

    return true;
  };

  const getTranslationUnavailableReason = (): string => {
    if (locale?.primary === true) {
      return "";
    }

    if (!hasPrimaryLanguageContent()) {
      return `Add content in ${getRegionPrimaryLanguage()} to enable translation`;
    }

    const primaryLanguage = getRegionPrimaryLanguage();
    const editLanguage = getCurrentEditLanguage();

    if (!editLanguage) {
      return "Cannot determine current edit language";
    }

    if (
      !supportedLanguages.includes(primaryLanguage) ||
      !supportedLanguages.includes(editLanguage)
    ) {
      return `Translation from ${primaryLanguage} to ${editLanguage} is not supported`;
    }

    return "";
  };

  const btns: TranslationButton[] = [
    {
      key: "translate",
      label: `Translate ${getRegionPrimaryLanguage()} → ${
        getCurrentEditLanguage() || ""
      }`,
      primary: true,
    },
    {
      key: "copy",
      label: "Copy Values",
      primary: false,
    },
  ];

  const onSelect = (btn: string): void => {
    switch (btn) {
      case "translate":
        onTranslate();
        break;
      case "copy":
        onCopy();
        break;
    }
  };

  const onTranslate = async (): Promise<void> => {
    setLoading(true);
    setError(false);
    setNoData(false);

    const primaryLanguage = getRegionPrimaryLanguage();
    const editLanguage = getCurrentEditLanguage();

    if (!editLanguage) {
      setError(true);
      setErrorMessage("Cannot determine current edit language");
      setLoading(false);
      return;
    }

    const createTranslateCallable = httpsCallable(
      functions,
      cloudFunctionNames.aitranslator
    );

    try {
      const filteredData = data.filter(
        (item): item is string => typeof item === "string" && item.trim() !== ""
      );

      if (filteredData.length <= 0) {
        setNoData(true);
        setLoading(false);
        return;
      }

      const structuredData: Record<string, string> = {};
      filteredData.forEach((item, index) => {
        structuredData[`field_${index}`] = item;
      });

      const result = await createTranslateCallable({
        data: {
          from: primaryLanguage,
          to: editLanguage,
          input_text: structuredData,
        },
      });

      setLoading(false);

      const responseData = result.data as {
        data: unknown;
        code?: number;
        message?: string;
      };

      if (responseData.code && responseData.code !== 200) {
        setError(true);
        setErrorMessage(responseData.message || "Translation service error");
        return;
      }

      if (responseData.data === "Service is unavailable at the moment.") {
        setError(true);
        setErrorMessage(responseData.data as string);
        return;
      }

      try {
        let translatedData: unknown = responseData.data;

        if (typeof translatedData === "string") {
          translatedData = JSON.parse(translatedData);
        }

        if (
          translatedData &&
          typeof translatedData === "object" &&
          "data" in (translatedData as object)
        ) {
          translatedData = (translatedData as Record<string, unknown>).data;
        }

        if (!translatedData || typeof translatedData !== "object") {
          setError(true);
          setErrorMessage("Received invalid translation response");
          return;
        }

        const dataArray: string[] = [];
        const translatedObj = translatedData as Record<string, unknown>;

        for (let i = 0; i < data.length; i++) {
          const fieldKey = `field_${i}`;
          if (fieldKey in translatedObj) {
            dataArray.push(String(translatedObj[fieldKey] || ""));
          } else {
            dataArray.push("");
          }
        }

        const translationResult: TranslationResult = { data: dataArray };
        onSetValue(translationResult);
      } catch (parseErr) {
        setError(true);
        setErrorMessage(`Error processing translation response: ${parseErr}`);
        logException(parseErr);
      }
    } catch (err) {
      setError(true);
      setErrorMessage(err instanceof Error ? err.message : String(err));
      setLoading(false);
      logException(err);
    }
  };

  const onCopy = (): void => {
    if (!copyNonTranslation) {
      setError(true);
      setErrorMessage("No function provided.");
      return;
    }

    if (error) setError(false);
    copyNonTranslation();
  };

  return (
    <>
      {isTranslationAvailable() ? (
        <>
          {error && (
            <StyledSpan>
              <Alert message={errorMessage} type="error" showIcon />
            </StyledSpan>
          )}
          {noData && (
            <StyledSpan>
              <Alert
                message={`Please enter ${getRegionPrimaryLanguage()} data`}
                type="warning"
                showIcon
              />
            </StyledSpan>
          )}
          <StyledSpan>
            {loading ? (
              <CenteredSpinner />
            ) : (
              btns
                .filter(e => {
                  if (hideTranslation && !showCopyButton) {
                    return false;
                  }

                  if (!showCopyButton) {
                    return e.key !== "copy";
                  }
                  if (hideTranslation) {
                    return e.key !== "translate";
                  }

                  return true;
                })
                .sort((a, b) =>
                  a.primary === b.primary ? 0 : b.primary ? 1 : -1
                )
                .map(e => {
                  return (
                    <span key={e?.key} style={{ marginRight: 8 }}>
                      <BJButton
                        key={e.key}
                        buttonType={
                          e.primary
                            ? ButtonTypes.Primary
                            : ButtonTypes.Secondary
                        }
                        size="small"
                        onClick={() => onSelect(e.key)}
                      >
                        {e.label}
                      </BJButton>
                    </span>
                  );
                })
            )}
          </StyledSpan>
        </>
      ) : (
        <>
          {!locale?.primary && getTranslationUnavailableReason() && (
            <StyledSpan>
              <Alert
                message={getTranslationUnavailableReason()}
                type="info"
                showIcon
              />
            </StyledSpan>
          )}
        </>
      )}
    </>
  );
};

const StyledSpan = styled("span")`
  display: flex;
  margin-bottom: 10px;
`;
