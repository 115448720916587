/**
 * TranslationSection.tsx
 *
 * A reusable React component that simplifies the integration of translation capabilities
 * into form-based pages. This component handles the display logic for translations,
 * determining when a translation is available and how to process it.
 *
 * USAGE:
 * 1. Import in your component:
 *    ```
 *    import { TranslationSection } from '../../components/TranslationSection';
 *    ```
 *
 * 2. Add to your JSX, typically near the form fields being translated:
 *    ```
 *    <TranslationSection
 *      locale={locale}                   // Current editing locale
 *      primaryLocale={primaryLocale}     // Source/primary locale for content
 *      translations={translations}       // The translations object from your form
 *      setValue={setValue}               // React Hook Form's setValue function
 *      availableFields={['title', 'intro', 'body']}  // Optional: fields to translate
 *      requiredFields={['title']}        // Optional: fields required for translation
 *    />
 *    ```
 *
 * This component:
 * - Automatically hides when editing the primary locale
 * - Shows an info message when required content is missing
 * - Dynamically determines which fields can be translated
 * - Handles passing appropriate data to AiTranslator
 *
 * It leverages useTranslationHandler to process translation results and update the form.
 */
import React, { useMemo } from "react";
import { Alert } from "antd";
import { AiTranslator } from "./AiTranslator";
import { useTranslationHandler } from "../hooks/useTranslationHandler";
import { TranslationSectionProps } from "../types/translation";

export const TranslationSection: React.FC<TranslationSectionProps> = ({
  locale,
  primaryLocale,
  translations,
  setValue,
  // Default to common fields, but allow customization
  availableFields = [
    // Common content fields - these are the main text fields used across most content types
    "title",
    "intro",
    "body",
    "description",
    "content",
    "text",

    // Question/Answer fields - used in FAQ and polls
    "question",
    "answer",
    "explanation",

    // Tips/Message fields - used in tips and notifications
    "tip",
    "message",

    // Button and CTA text - visible user-facing text
    "buttonText",
    "ctaBtnLabel",
    "successButtonText",

    // Header text fields - used in various components
    "header",
    "subHeader",
    "heading",

    // Special content fields from specific components
    "name", // Used for episode names, category names, etc.
    "footer", // Footer text that appears in various components
  ],
  // By default, don't require any specific fields
  requiredFields = [],
}) => {
  const { handleTranslation, prepareTranslationData } =
    useTranslationHandler(setValue);

  const existingFields = useMemo(() => {
    if (!translations || !translations[primaryLocale.key]) {
      return [];
    }

    const sourceData = translations[primaryLocale.key];
    return availableFields.filter(
      field =>
        field in sourceData &&
        sourceData[field] !== null &&
        sourceData[field] !== undefined &&
        (typeof sourceData[field] !== "string" || sourceData[field] !== "")
    );
  }, [translations, primaryLocale, availableFields]);

  // Check if ANY fields exist (not just required ones)
  const hasContentToTranslate = useMemo(() => {
    return existingFields.length > 0;
  }, [existingFields]);

  // Check required fields only if requiredFields array is not empty
  const hasRequiredFields = useMemo(() => {
    if (requiredFields.length === 0) return true;
    return requiredFields.every(field => existingFields.includes(field));
  }, [existingFields, requiredFields]);

  if (locale.key === primaryLocale.key) {
    return null;
  }

  if (!hasContentToTranslate) {
    return (
      <Alert
        message="Translation unavailable"
        description={`Please add content in ${primaryLocale.label} first before translating`}
        type="info"
        showIcon
        style={{ marginBottom: 16 }}
      />
    );
  }

  if (!hasRequiredFields) {
    return (
      <Alert
        message="Required fields missing"
        description={`Please add ${requiredFields.join(", ")} in ${
          primaryLocale.label
        } first before translating`}
        type="info"
        showIcon
        style={{ marginBottom: 16 }}
      />
    );
  }

  const translationData = prepareTranslationData(
    translations,
    primaryLocale.key,
    existingFields
  );

  return (
    <AiTranslator
      locale={locale}
      data={translationData}
      onSetValue={result => handleTranslation(result, locale, existingFields)}
      toLanguage={locale.label}
    />
  );
};
