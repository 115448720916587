export enum RoleTypes {
  moderator,
  admin,
  analytic,
  notificationHandler,
}

export enum Languages {
  english = "en",
  swedish = "sv",
  norwegian = "no",
  german = "de",
  danish = "da",
}
export enum ContentListType {
  AllContent = "allContent",
  RecommendedContent = "recommendedContent",
  PopularContent = "popularContent",
  OnlyContentCollection = "onlyContentCollection",
  EntertainMeContent = "entertainMeContent",
}
export enum CommonContentType {
  ARTICLE = "article",
  BLOG = "blogPost",
  PODCAST = "podcastEpisode",
  FAQ = "faq",
  OFFER = "offer",
  DIETARYADVICE = "dietaryAdvice",
  CONTENTCOLLECTION = "contentCollection",
  TOOL = "tool",
}

export enum AppNavigationType {
  ARTICLE = "article",
  BLOG = "blogPost",
  PODCAST = "podcastEpisode",
  FAQ = "faq",
  OFFER = "offer",
  EXTERNAL = "external",
  SIGNUP = "signup",
  INVITE = "invite",
  DEEP_LINK = "deepLink",
}

export enum NotificationTopics {
  Pregnancy = "pregnancy",
  Children = "children",
  Partner = "partner",
  Reminder = "reminder",
  Update = "update",
  Offer = "offer",
}

export enum UserSegmantType {
  childrenInMonthsBetween = 1, //   All users with children in month 1-12
  childrenInOddMonths = 2, // All users with children in odd/even month
  childrenInEvenMonths = 3, // All users with pregnancies in week 35 and up.
  pregnanciesInOrAboveWeekNumber = 4, // All users with pregnancies in week 35 and up.
  usersWithPartners = 5, // All users who has a partner
  usersWithSelectedTown = 6, // All users who has set their home town to Stockholm
  usersWithCategorySpecified = 7, // All users who has specified Training as one of their interests
  allUsers = 8, // All users
  usersNotParticipatedInCompetition = 9, // Users who have not participated in the competition
}

export enum PopularType {
  MONTH = "month",
  WEEK = "week",
}

export enum PopularAudienceType {
  CHILD = "child",
  PREGNANCY = "pregnancy",
}

export enum CheckListType {
  pregnancy = "pregnancy",
  children = "children",
  infant = "infant",
  general = "general", // general type is not activated yet
  gettingStarted = "gettingStarted",
}

export enum NotificationComponentTypes {
  Pregnancy = "pregnancy",
  Children = "children",
}

export enum RemoteNotificationRecordType {
  draft = "draft",
  duplicatedDraft = "duplicatedDraft",
  completed = "completed",
  error = "error",
  pending = "pending",
  scheduled = "scheduled",
  schedulePending = "schedulePending",
  scheduleDeletePending = "scheduleDeletePending",
  scheduleDeleted = "scheduleDeleted",
}

export enum ScreenInMobile {
  Content = "discover",
  Child = "home.child",
  Pregnancy = "home.pregnancy",
}

export enum BlogTypes {
  BlogPost = "blogpost",
  ChildBlogPost = "childblogpost",
  BirthStory = "birthstory",
  GeneralBlogPost = "generalblogpost",
}

export enum ContentType {
  Article = "article",
  FAQ = "faq",
  PodcastEpisode = "podcastEpisode",
  Pregnancy = "blogPregnancy",
  Birth = "blogBirth",
  Child = "blogChild",
  General = "generalblogpost",
  Checklist = "checklist",
  BlogPost = "blogpost",
  BirthStories = "birthstory",
  ChildBlogpost = "childblogpost",
  Offer = "offer",
  Podcast = "podcast",
  Discover = "discover",
  Dietary = "dietaryAdvice",
  ArticleCategories = "articleCategories",
}

export enum DeepLinkType {
  Article = "articles",
  FAQ = "faqs",
  PodcastEpisode = "podcastEpisodes",
  Checklist = "checklists",
  BlogPost = "blogpost",
  ChildBlogPost = "childblogpost",
  GeneralBlogPost = "generalblogpost",
  BirthStories = "birthstory",
  Offer = "offers",
  Podcast = "podcasts",
  Dietary = "dietaryAdvices",
  ArticleCategories = "articleCategories",
  ContentCollection = "contentCollection",
  AppTakeOver = "appTakeOver",
  MiniJourney = "miniJourney",
  Competition = "competition",
}

export enum AppLinksScreens {
  articles = "articles",
  birthStories = "birthStory",
}

export enum AppEnvironments {
  Production = "PRODUCTION",
  Development = "DEVELOPMENT",
  Staging = "STAGING",
}

export enum TipsType {
  Pregnancy = "pregnancy",
  Child = "child",
}

export enum BannerDisplayableSegment {
  pregnanciesWithInWeeksRange = "pregnanciesWithInWeeksRange",
  childrenWithInMonthsRange = "childrenWithInMonthsRange",
  childrenWithInWeeksRange = "childrenWithInWeeksRange",
  hasPartner = "hasPartner",
  allUsers = "allUsers",
  hasLoyalty = "hasLoyalty",
}

export enum AudienceType {
  Pregnancy = "pregnancy",
  Child = "child",
  Partner = "partner",
  All = "all",
}

export enum DisplayUnitType {
  Month = "month",
  Week = "week",
  Day = "day",
}

export enum MilestonesType {
  Pregnancy = "pregnancy",
  Child = "child",
}

export enum MissionUnavailable {
  Never = "never",
  IsPartner = "isPartner",
  NoChild = "noChild",
  NoPregnancyAndChildOlder = "noPregnancyAndChildOlder",
}

export enum ExperimentStatus {
  BETA = "beta",
  LIVE = "live",
}

export enum LoyaltyFeature {
  Babylog = "babylog",
  BirthPlan = "birthPlan",
  BirthStory = "birthStory",
  Blogposts = "blogposts",
  Checklists = "checklists",
  Competitions = "competitions",
  CompetitionsDoubleEntries = "competitionsDoubleEntries",
  Courses = "courses",
  DailyDoula = "dailyDoula",
  EventInvitations = "eventInvitations",
  FaqChild = "faqChild",
  InvitePartner = "invitePartner",
  MoodTracker = "moodTracker",
  PreParenting = "preParenting",
  ShareOnSoMe = "shareOnSoMe",
  ShareSave = "shareSave",
  SpecialOffers = "specialOffers",
  Whitenoise = "whitenoise",
  WorkoutClasses = "workoutClasses",
  OthersInSameWeek = "othersInSameWeek",
}

export enum LoyaltyPopupType {
  Locked = "locked",
  TierDowngrade = "tierDowngrade",
  TierSecured = "tierSecured",
  TierWarning = "tierWarning",
  FastForward = "fastForward",
}

export enum MeasurementSystem {
  Metric = "metric",
  Imperial = "imperial",
}

export enum ContentTypeNames {
  checklists = "Checklists",
  podcasts = "Podcasts",
  episodes = "Podcast Episodes",
  blogposts = "Blog Posts",
  childBlogPosts = "Child Blog Posts",
  birthStories = "Birth Stories",
  generalBlogPosts = "General Blog Posts",
  articles = "Articles",
  dietaryAdvices = "Dietary Advices",
  faq = "Frequently Asked Questions",
  offers = "Offers",
  seasons = "PodcastSeasons",
}

export enum OneLinkTypes {
  appToApp = "App-to-app",
  emailToApp = "Email-to-app",
  socialToApp = "Social-to-app",
}

export type AppType = "pregnancy" | "child";

export type ToolType =
  | "dailyMood"
  | "preParentConversation"
  | "birthPlan"
  | "birthStory"
  | "podcastEpisodes"
  | "eyeColorCalculator"
  | "nameGenerator"
  | "lengthCalculator"
  | "adjustProfilePage"
  | "shareAccount"
  | "addChild"
  | "adjustDueDate"
  | "addPregnancy"
  | "support"
  | "notification"
  | "weeklyInformation"
  | "monthlyInformation"
  | "blogListPregnancy"
  | "blogListChild"
  | "myRewards"
  | "profileTab"
  | "pointsRewards"
  | "offers"
  | "courses";

export type ToolLocale = "sv" | "en" | "no" | "da" | "de";
