import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Col, Form, Row, Typography } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCountry, usePodcast, usePodcasts } from "../../context";
import { ImagesService } from "../../services";

import { CheckboxButton, FormEdit, FormEditType } from "../../components";
import { PodcastMessages } from "../../language";
import { EpisodeList, EpisodeModal, SeasonList, SeasonModal } from "./";
import {
  AspectRatio,
  ContentTypeNames,
  DeepLinkType,
  capitalizeFirstLetter,
  formValidationError,
  getMax,
} from "../../utils";
import {
  BJFormDatePicker,
  BJInputFormItem,
  BJTagsFormItem,
} from "../../components/theme";
import { DropAndCrop } from "../../components/DropAndCrop";
import { TranslationSection } from "../../components/TranslationSection";
import { SetValueFunction, TranslationsObject } from "../../types/translation";

type FormValues = {
  translations: {
    [key: string]: {
      title: string;
      description?: string;
      tagWords?: string[];
    };
  };
  imageUrl: string;
  blurhash?: string;
  promoted: boolean;
  publishDate: string;
};

export const PodcastPage = () => {
  const { currentCountry, primaryLocale } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title ${item.key.toUpperCase()}: ${
                PodcastMessages.requiredValidationText
              }`
            ),
        });
        return acc;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, {} as any)
    ),
    imageUrl: yup
      .string()
      .notRequired()
      .url(`Image Url ${PodcastMessages.urlValidationText}`),
  });

  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { podcast, episodes, seasons, loading, update, deletePodcast } =
    usePodcast(id);

  const { podcasts, createPodcast, updatePodcastImageUrl } = usePodcasts();
  const [showEpisodeModal, setShowEpisodeModal] = useState(false);
  const [selectEpisode, setSelectEpisode] = useState<PodcastEpisode | null>(
    null
  );
  const [showSeasonModal, setShowSeasonModal] = useState(false);
  const [selectSeason, setSelectSeason] = useState<PodcastSeason | null>(null);
  const {
    formState,
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: { promoted: false },
  });
  useEffect(() => {
    if (podcast) {
      reset({
        ...podcast,
      });
    } else {
      reset({
        imageUrl: "",
        publishDate: "",
        blurhash: "",
      });
    }
  }, [podcast, reset]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    try {
      const {
        imageUrl,
        promoted,
        publishDate,
        blurhash,
        translations: translationsData,
      } = data;
      const translations = Object.entries(translationsData).reduce<
        typeof translationsData
      >((acc, [key, value]) => {
        return (acc[key] = { ...value, tagWords: value.tagWords ?? [] }), acc;
      }, {});
      if (podcast) {
        await update({
          imageUrl,
          publishDate,
          blurhash,
          promoted: promoted ?? false,
          isPromoted: podcast.isPromoted ?? false,
          sortOrder: podcast.sortOrder,
          translations,
        });
        await updatePodcastImageUrl(
          podcast.id,
          episodes,
          podcast.imageUrl,
          podcast.blurhash
        );
      } else {
        let highestSortOrder = getMax(podcasts, "sortOrder");
        const { id: createdPodcastId } = await createPodcast({
          imageUrl,
          blurhash,
          promoted: promoted ?? false,
          isPromoted: false,
          publishDate: data.publishDate,
          sortOrder: highestSortOrder++,
          translations,
        });
        return navigate(`../${createdPodcastId}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleImageImageUrl = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };

  const handleImageBlurhash = (url: string | null) => {
    setValue("blurhash", url, { shouldDirty: true });
  };

  const onRemove = async () => {
    await deletePodcast();
    return navigate("./..");
  };

  const translations = watch("translations");
  const isDirty = !!Object.keys(formState.dirtyFields).length;

  return (
    <FormEdit
      onRemove={onRemove}
      enableSave={isDirty}
      title={podcast ? podcast?.translations : "New podcast"}
      id={podcast?.id}
      editType={podcast?.id ? FormEditType.EDIT : FormEditType.ADD}
      loading={loading}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      deepLink={{
        type: DeepLinkType.Podcast,
        id: podcast?.id,
        countryCode: currentCountry?.abb,
      }}
      localeSupported
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errors={errors as any[]}
    >
      {locale => (
        <>
          {podcast && (
            <EpisodeModal
              show={showEpisodeModal || !!selectEpisode}
              episode={selectEpisode}
              seasons={seasons ?? []}
              podcast={podcast}
              onHide={() => {
                setShowEpisodeModal(false);
                setSelectEpisode(null);
              }}
            />
          )}
          {podcast && (
            <SeasonModal
              show={showSeasonModal || !!selectSeason}
              season={selectSeason}
              podcastId={podcast!.id}
              onHide={() => {
                setShowSeasonModal(false);
                setSelectSeason(null);
              }}
            />
          )}

          <Row gutter={50}>
            <Col lg={12}>
              <BJInputFormItem
                size="large"
                {...register(`translations.${locale?.key}.title`)}
                required
                control={control}
                error={!!errors.translations?.[locale?.key]?.title?.message}
                label={`Title (${capitalizeFirstLetter(locale?.key)})`}
                message={errors.translations?.[locale?.key]?.title?.message}
                fieldName={`translations.${locale?.key}.title`}
                key={`translations.${locale?.key}.title`}
              />
              <BJInputFormItem
                control={control}
                error={
                  !!errors.translations?.[locale?.key]?.description?.message
                }
                label={`Description (${capitalizeFirstLetter(locale?.key)})`}
                rows={6}
                message={
                  errors.translations?.[locale?.key]?.description?.message
                }
                fieldName={`translations.${locale?.key}.description`}
                key={`translations.${locale?.key}.description`}
                {...register(`translations.${locale?.key}.description`)}
              />
              <TranslationSection
                locale={locale}
                primaryLocale={primaryLocale}
                translations={translations as TranslationsObject}
                setValue={setValue as SetValueFunction}
                availableFields={["title", "description"]}
                requiredFields={["title"]}
              />
              <Controller
                control={control}
                name="promoted"
                render={({ field: { onChange, value } }) => (
                  <CheckboxButton
                    checked={value}
                    label="Promote"
                    onChange={onChange}
                  />
                )}
              />
              {podcast && (
                <SeasonList
                  initialSeasons={seasons ?? []}
                  podcastId={id}
                  onAdd={() => setShowSeasonModal(true)}
                  onSelect={setSelectSeason}
                />
              )}
            </Col>
            <Col lg={12}>
              <Form.Item
                required
                label="Image"
                validateStatus={errors.imageUrl && "error"}
                help={
                  <Typography.Paragraph type="danger">
                    {errors.imageUrl?.message}
                  </Typography.Paragraph>
                }
              >
                <Row>
                  <DropAndCrop
                    title="Image"
                    {...register("imageUrl")}
                    allowNaturalImageUpload
                    initialUrl={podcast?.imageUrl}
                    setUploadUrl={handleImageImageUrl}
                    setBlurhash={handleImageBlurhash}
                    uploadImage={ImagesService.uploadImageImage}
                    lockedRatio={AspectRatio.Free}
                    defaultCropBoxWidth={100}
                    defaultCropBoxHeight={100}
                  />
                </Row>
              </Form.Item>
              <BJTagsFormItem
                control={control}
                fieldName={`translations.${locale?.key}.tagWords`}
                label={`Tag words (${capitalizeFirstLetter(locale?.key)})`}
                setValue={setValue}
                aiTagWordsPayload={{
                  contentTitle: translations?.[locale.key]?.title,
                  contentBody: translations?.[locale.key]?.description,
                  contentType: ContentTypeNames.podcasts,
                  documentId: podcast?.id,
                  language: locale.label,
                }}
                aiTranslateTagWordsPayload={{
                  tagWords: translations?.[primaryLocale.key]?.tagWords,
                  from: primaryLocale.label,
                }}
                existingTags={translations?.[locale.key]?.tagWords || []}
                locale={locale.key}
              />
              <BJFormDatePicker
                control={control}
                fieldName={"publishDate"}
                label="Publish date"
                message={errors?.publishDate?.message}
                error={!!errors?.publishDate?.message}
                format={"YYYY-MM-DD"}
                size={"large"}
              />
              {podcast && (
                <EpisodeList
                  initialEpisodes={episodes ?? []}
                  podcastId={id}
                  onAdd={() => setShowEpisodeModal(true)}
                  onSelect={setSelectEpisode}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </FormEdit>
  );
};
