/**
 * useTranslationHandler.ts
 *
 * A custom React hook that provides a simplified interface for handling AI translations
 * in form-based pages. This hook encapsulates the logic for processing translation
 * results and updating form fields with translated content.
 *
 * USAGE:
 * 1. Import in your component:
 *    ```
 *    import { useTranslationHandler } from '../hooks/useTranslationHandler';
 *    ```
 *
 * 2. Initialize in your component with your form's setValue function:
 *    ```
 *    const { handleTranslation, prepareTranslationData } = useTranslationHandler(setValue);
 *    ```
 *
 * 3. Use with AiTranslator:
 *    ```
 *    <AiTranslator
 *      locale={locale}
 *      data={prepareTranslationData(translations, primaryLocale.key, ['title', 'intro'])}
 *      onSetValue={result => handleTranslation(result, locale, ['title', 'intro'])}
 *      toLanguage={locale.label}
 *    />
 *    ```
 *
 * This hook leverages the translationHelpers.ts utility functions to process translation
 * responses and update form values.
 */
import { useCallback } from "react";
import {
  processTranslationResponse,
  updateFormWithTranslations,
} from "../utils/translationHelpers";
import { logException } from "../utils/exceptionLogger";
import {
  TranslationResult,
  SetValueFunction,
  TranslationsObject,
  FieldMapping,
} from "../types/translation";

/**
 * Custom hook to handle AI translations in forms
 * @param setValue Function from React Hook Form to update form values
 * @return Object containing utility functions for translation handling
 */
export const useTranslationHandler = (setValue: SetValueFunction) => {
  /**
   * Creates translation field mappings based on target language and field names
   * @param targetLanguageKey The language key to translate to (e.g., 'en', 'sv')
   * @param fieldNames Array of field names to map for translation
   * @return Mapping object with form field paths
   */
  const createTranslationMapping = useCallback(
    (targetLanguageKey: string, fieldNames: string[]): FieldMapping => {
      return {
        common: fieldNames.map(
          field => `translations.${targetLanguageKey}.${field}`
        ),
      };
    },
    []
  );

  /**
   * Process translation result and update form values
   * @param translationResult The raw translation result from the API
   * @param targetLocale The locale object for the target language
   * @param fieldNames Array of field names to update with translations
   */
  const handleTranslation = useCallback(
    (
      translationResult: TranslationResult,
      targetLocale: Locale,
      fieldNames: string[] = []
    ): void => {
      const processedTranslationValues =
        processTranslationResponse(translationResult);

      const targetLanguageKey = targetLocale?.key;

      if (!targetLanguageKey) {
        logException(
          new Error("Translation failed: Could not determine target language")
        );
        return;
      }

      const translationFieldMapping = createTranslationMapping(
        targetLanguageKey,
        fieldNames
      );

      updateFormWithTranslations(
        processedTranslationValues,
        setValue,
        translationFieldMapping
      );
    },
    [createTranslationMapping, setValue]
  );

  /**
   * Prepare data array for AiTranslator from source translations
   * @param sourceTranslations The translations object containing all locales
   * @param sourceLanguageKey The key of the source locale to translate from
   * @param fieldNames Array of field names to prepare for translation
   * @return Array of values ready to be sent to AiTranslator
   */
  const prepareTranslationData = useCallback(
    (
      sourceTranslations: TranslationsObject,
      sourceLanguageKey: string,
      fieldNames: string[] = []
    ): string[] => {
      if (!sourceTranslations || !sourceTranslations[sourceLanguageKey]) {
        return [];
      }

      // Only collect fields that actually exist in the translations
      const sourceData = sourceTranslations[sourceLanguageKey];
      return fieldNames
        .filter(
          field =>
            field in sourceData &&
            sourceData[field] !== null &&
            sourceData[field] !== undefined &&
            (typeof sourceData[field] !== "string" || sourceData[field] !== "")
        )
        .map(field => {
          const value = sourceData[field];
          if (typeof value === "string") return value.trim() || "";
          if (Array.isArray(value)) return value.join(", ");
          return String(value || "");
        });
    },
    []
  );

  return {
    handleTranslation,
    prepareTranslationData,
    createTranslationMapping,
  };
};
