import { useState, useEffect, memo, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Space,
  Modal,
  Form,
  message,
  Alert,
  Tag,
  Table,
  Tooltip,
  Card as AntCard,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { BJContainer } from "../../components/theme";
import styled from "styled-components";
import { useCountry } from "../../context";
import { DoulaServices } from "../../services/DoulaServices";
import { logException } from "../../utils/exceptionLogger";
import { useDoulaQuestions } from "../../hooks";

const { Title, Text } = Typography;
const { Option } = Select;

interface Question {
  id: string;
  type: string[];
  preg_span_week: string[];
  child_span_month: string[];
  translations: QuestionTranslations;
  createdAt?: { seconds: number; nanoseconds: number } | null;
  updatedAt?: { seconds: number; nanoseconds: number } | null;
}

interface Category {
  id: string;
  name: string;
  questions: Record<string, Question>;
}

type QuestionTranslations = Record<string, string>;

interface QuestionFormValues {
  categoryId: string;
  translationKey: string;
  type: string[];
  preg_span_week: string[];
  child_span_month: string[];
  translations: QuestionTranslations;
}

export const DailyDoulaQuestionsPage = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [searchText, setSearchText] = useState("");
  const { primaryLocale, allCountries } = useCountry();
  const { data, loading: dataLoading, error, refetch } = useDoulaQuestions();

  const formatDate = useCallback(
    (
      timestamp: { seconds: number; nanoseconds: number } | null | undefined
    ) => {
      if (!timestamp) return "N/A";
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleDateString();
    },
    []
  );

  useEffect(() => {
    if (data) {
      const categoriesArray: Category[] = [];

      for (const [categoryId, categoryData] of Object.entries(data)) {
        const category: Category = {
          id: categoryId,
          name: categoryId,
          questions: {},
        };

        if (categoryData.questions) {
          category.questions = Object.entries(categoryData.questions).reduce(
            (acc, [questionId, questionData]) => {
              acc[questionId] = {
                id: questionId,
                ...questionData,
                translations: questionData.translations,
              };
              return acc;
            },
            {} as Record<string, Question>
          );
        }

        categoriesArray.push(category);
      }

      setCategories(categoriesArray);

      if (!selectedCategory && categoriesArray.length > 0) {
        setSelectedCategory(categoriesArray[0].id);
      }
    }
  }, [data]);

  const handleOperationResult = (success: boolean, successMessage: string) => {
    if (success) {
      message.success(successMessage);
    } else {
      message.error(`Failed to ${successMessage.toLowerCase()}`);
    }
  };

  const updateDailyDoulaQuestion = async (values: QuestionFormValues) => {
    try {
      const questionId = values.translationKey;
      const { categoryId } = values;

      const formattedQuestionData = {
        type: values.type || [],
        preg_span_week: values.preg_span_week || [],
        child_span_month: values.child_span_month || [],
        translations: values.translations || {
          en: "",
          sv: "",
          no: "",
          da: "",
          de: "",
        },
      };

      if (editingQuestion) {
        await DoulaServices.updateDailyDoulaQuestion(
          categoryId,
          questionId,
          formattedQuestionData
        );

        await refetch();
        handleOperationResult(true, "Question updated");
      } else {
        await DoulaServices.createDailyDoulaQuestion(
          categoryId,
          questionId,
          formattedQuestionData
        );

        await refetch();
        handleOperationResult(true, "Question created");
      }

      setIsQuestionModalVisible(false);
    } catch (error) {
      logException(error);
      message.error("Failed to save question");
    }
  };

  const handleDeleteQuestion = (questionId: string) => {
    if (!questionId) return;

    Modal.confirm({
      title: "Are you sure you want to delete this question?",
      content: "This action cannot be undone.",
      okText: "Yes, delete it",
      okType: "danger",
      cancelText: "No, keep it",
      async onOk() {
        try {
          const result = await DoulaServices.deleteDailyDoulaQuestion(
            questionId
          );
          handleOperationResult(result.success, "Question deleted");
          if (result.success) {
            refetch();
          }
        } catch (error) {
          logException(error);
          message.error("Failed to delete question");
        }
      },
    });
  };

  const handleAddQuestion = () => {
    setEditingQuestion(null);
    setIsQuestionModalVisible(true);
  };

  const handleEditQuestion = (question: Question) => {
    setEditingQuestion(question);
    setIsQuestionModalVisible(true);
  };

  const getFilteredQuestions = (categoryId: string) => {
    if (!data || !data[categoryId] || !data[categoryId].questions) {
      return [];
    }

    const questions = Object.entries(data[categoryId].questions).map(
      ([id, question]) => ({
        id,
        ...(question as Question),
      })
    );

    if (!searchText) return questions;

    return questions.filter(question => {
      if (question.id.toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }

      if (question.translations) {
        const translationValues = Object.values(question.translations);
        for (const translation of translationValues) {
          if (
            translation &&
            typeof translation === "string" &&
            translation.toLowerCase().includes(searchText.toLowerCase())
          ) {
            return true;
          }
        }
      }

      return false;
    });
  };

  const getCategoryStats = () => {
    return categories.map(category => {
      const questionCount = Object.keys(category.questions).length;
      return {
        id: category.id,
        name: category.name,
        count: questionCount,
      };
    });
  };

  const renderQuestionList = (categoryId: string) => {
    const questions = getFilteredQuestions(categoryId);

    return (
      <div>
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Input
            placeholder="Search questions..."
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            style={{ width: 300 }}
            allowClear
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddQuestion}
          >
            Add Question
          </Button>
        </div>

        <Table
          dataSource={questions}
          columns={getQuestionColumns()}
          rowKey="id"
          pagination={{ pageSize: 10 }}
          expandable={{
            expandedRowRender: record => (
              <TranslationsPanel translations={record.translations} />
            ),
          }}
          loading={dataLoading}
        />
      </div>
    );
  };

  const getQuestionColumns = () => {
    return [
      {
        title: "Translation Key",
        dataIndex: "id",
        key: "translationKey",
        width: 150,
      },
      {
        title: "Question",
        dataIndex: "translations",
        key: "question",
        render: (translations: QuestionTranslations) =>
          translations[primaryLocale.key as keyof QuestionTranslations] ||
          translations.en,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (types: string[]) => (
          <Space>
            {types?.map(type => (
              <Tag
                key={type}
                color={type === "preg" ? "#d5ddf8" : "#FDE9EE"}
                style={{ color: "#4d3d71" }}
              >
                {type === "preg" ? "Pregnancy" : "Child"}
              </Tag>
            ))}
          </Space>
        ),
      },
      {
        title: "Week/Month Range",
        key: "range",
        render: (_: unknown, record: Question) => (
          <>
            {record.preg_span_week?.length > 0 && (
              <div>
                <Text type="secondary">Weeks: </Text>
                {record.preg_span_week.map(week => (
                  <Tag key={week}>{week}</Tag>
                ))}
              </div>
            )}
            {record.child_span_month?.length > 0 && (
              <div>
                <Text type="secondary">Months: </Text>
                {record.child_span_month.map(month => (
                  <Tag key={month}>{month}</Tag>
                ))}
              </div>
            )}
          </>
        ),
      },
      {
        title: "Updated",
        key: "updated",
        width: 100,
        render: (_: unknown, record: Question) => (
          <Tooltip title={`Created: ${formatDate(record.createdAt)}`}>
            {formatDate(record.updatedAt)}
          </Tooltip>
        ),
      },
      {
        title: "Actions",
        key: "actions",
        render: (_: unknown, record: Question) => (
          <Space>
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEditQuestion(record)}
              type="text"
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteQuestion(record.id || "")}
              type="text"
              danger
            />
          </Space>
        ),
      },
    ];
  };
  const TranslationsPanel = memo(
    function TranslationsPanel({
      translations,
    }: {
      translations: QuestionTranslations;
    }) {
      return (
        <div style={{ padding: "0 20px" }}>
          <Title level={5}>Translations</Title>
          <Row gutter={[16, 16]}>
            {Object.entries(translations).map(([lang, text]) => (
              <Col span={8} key={lang}>
                <AntCard>
                  <Text strong>{lang.toUpperCase()}: </Text>
                  <Text>{text}</Text>
                </AntCard>
              </Col>
            ))}
          </Row>
        </div>
      );
    },
    (prevProps, nextProps) => prevProps.translations === nextProps.translations
  );

  const QuestionModal = () => {
    const [form] = Form.useForm();

    useEffect(() => {
      if (isQuestionModalVisible) {
        const initialValues = editingQuestion
          ? {
              categoryId: selectedCategory,
              type: editingQuestion.type,
              preg_span_week: editingQuestion.preg_span_week,
              child_span_month: editingQuestion.child_span_month,
              translationKey: editingQuestion.id,
              translations: editingQuestion.translations,
            }
          : {
              categoryId: selectedCategory,
              type: ["preg"],
              preg_span_week: [],
              child_span_month: [],
              translationKey: "",
              translations: {
                en: "",
                sv: "",
                no: "",
                da: "",
                de: "",
              },
            };
        form.setFieldsValue(initialValues);
      }
    }, [form, isQuestionModalVisible, editingQuestion, selectedCategory]); // Coderabbit

    const checkKeyUniqueness = async (key: string, categoryId: string) => {
      if (editingQuestion && editingQuestion.id === key) return true;

      const category = categories.find(cat => cat.id === categoryId);
      return !category?.questions?.[key];
    };

    return (
      <Modal
        title={editingQuestion ? "Edit Question" : "Add New Question"}
        visible={isQuestionModalVisible}
        onCancel={() => setIsQuestionModalVisible(false)}
        footer={null}
        width={800}
      >
        <Form form={form} layout="vertical" onFinish={updateDailyDoulaQuestion}>
          <Form.Item
            name="categoryId"
            label="Category"
            rules={[{ required: true, message: "Please select a category" }]}
          >
            <Select>
              {categories.map(category => (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="translationKey"
            label="Translation Key"
            rules={[
              { required: true, message: "Please enter a translation key" },
              {
                pattern: /^[a-zA-Z0-9_]+$/,
                message:
                  "Translation key can only contain letters, numbers, and underscores",
              },
              {
                validator: async (_, value) => {
                  if (!value) return Promise.resolve();
                  const isUnique = await checkKeyUniqueness(
                    value,
                    form.getFieldValue("categoryId")
                  );
                  return isUnique
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("This translation key already exists")
                      );
                },
              },
            ]}
            tooltip="A unique identifier for this question (e.g. nauseaTips, braxtonHicks)"
          >
            <Input placeholder="Enter a unique key (e.g. nauseaTips)" />
          </Form.Item>

          <Form.Item
            name="type"
            label="Type"
            rules={[
              { required: true, message: "Please select at least one type" },
            ]}
          >
            <Select mode="multiple">
              <Option value="preg">Pregnancy</Option>
              <Option value="child">Child</Option>
            </Select>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue }) => {
              const types = getFieldValue("type") || [];

              return (
                <>
                  {types.includes("preg") && (
                    <Form.Item
                      name="preg_span_week"
                      label="Pregnancy Week Span"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select at least one week span for pregnancy questions",
                        },
                      ]}
                    >
                      <Select mode="multiple">
                        <Option value="1-13">1-13 (First Trimester)</Option>
                        <Option value="14-28">14-28 (Second Trimester)</Option>
                        <Option value="29-42">29-42 (Third Trimester)</Option>
                      </Select>
                    </Form.Item>
                  )}

                  {types.includes("child") && (
                    <Form.Item
                      name="child_span_month"
                      label="Child Month Span"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select at least one month span for child questions",
                        },
                      ]}
                    >
                      <Select mode="multiple">
                        <Option value="0-3">0-3 months</Option>
                        <Option value="4-6">4-6 months</Option>
                        <Option value="7-12">7-12 months</Option>
                        <Option value="13-24">13-24 months</Option>
                        <Option value="25-36">25-36 months</Option>
                      </Select>
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Item>

          <div>
            <Title level={5} style={{ marginBottom: 16 }}>
              Translations
            </Title>

            {allCountries.map(country => {
              const localeKey = country.locales[0].key;
              const isGermany = country.name === "Germany";
              const displayKey = isGermany ? "de" : localeKey;

              return (
                <AntCard
                  key={`lang-${displayKey}`}
                  style={{ marginBottom: 16 }}
                >
                  <Form.Item
                    name={["translations", displayKey]}
                    label={`${country.name} (${displayKey})`}
                    rules={[
                      {
                        required: true,
                        message: `${country.name} translation is required`,
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={`Enter ${country.name} translation`}
                    />
                  </Form.Item>
                </AntCard>
              );
            })}
          </div>

          <Form.Item style={{ marginTop: 16, textAlign: "right" }}>
            <Space>
              <Button onClick={() => setIsQuestionModalVisible(false)}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                {editingQuestion ? "Update" : "Add"} Question
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <BJContainer>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={2}>Daily Doula Questions</Title>
            <Space>
              <Button
                icon={<ReloadOutlined />}
                onClick={() => refetch()}
                loading={dataLoading}
              >
                Refresh Data
              </Button>
            </Space>
          </div>
          <Text>
            Manage the questions that will be shown to users in the Daily Doula
            feature.
          </Text>
          {error && (
            <Alert
              message="Error loading data"
              description={error.message}
              type="error"
              showIcon
              style={{ marginTop: 16 }}
            />
          )}
        </Col>
        <Col span={24}>
          <Card>
            <div style={{ display: "flex", height: 850 }}>
              <div
                style={{
                  width: "25%",
                  maxWidth: 250,
                  minWidth: 180,
                  borderRight: "1px solid #f0f0f0",
                  overflow: "auto",
                  paddingRight: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px 16px",
                    borderBottom: "1px solid #f0f0f0",
                    fontWeight: "bold",
                  }}
                >
                  <span>Category</span>
                  <span>Questions</span>
                </div>

                {getCategoryStats().map(category => (
                  <div
                    key={category.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px 16px",
                      cursor: "pointer",
                      backgroundColor:
                        selectedCategory === category.id
                          ? "#f6f6f6"
                          : "transparent",
                      borderLeft:
                        selectedCategory === category.id
                          ? "3px solid #1890ff"
                          : "3px solid transparent",
                    }}
                    onClick={() => setSelectedCategory(category.id)}
                  >
                    <span
                      className="category-name"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {category.name}
                    </span>
                    <span
                      style={{
                        backgroundColor:
                          category.count > 0 ? "#E7E5ED" : "#f0f0f0",
                        color: category.count > 0 ? "#4d3d71" : "#999999",
                        borderRadius: "10px",
                        padding: "0 8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        minWidth: "24px",
                        textAlign: "center",
                        display: "inline-block",
                        marginLeft: "8px",
                        flexShrink: 0,
                      }}
                    >
                      {category.count}
                    </span>
                  </div>
                ))}
              </div>

              <div style={{ flex: 1, padding: "0 16px", overflow: "auto" }}>
                {selectedCategory && renderQuestionList(selectedCategory)}
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <QuestionModal />
    </BJContainer>
  );
};

const Card = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #f0f0f0;
`;
