import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { maximumWeek } from "../";
import { FormModal } from "../../../components";
import { DropAndCrop } from "../../../components/DropAndCrop";
import { commonErrors, dayModalMessages } from "../../../language";
import {
  AspectRatio,
  formValidationError,
  getFormattedErrorMessage,
} from "../../../utils";
import { useCountry } from "../../../context";
import { BJInputFormItem } from "../../../components/theme";
import { TranslationSection } from "../../../components/TranslationSection";
import {
  SetValueFunction,
  TranslationsObject,
} from "../../../types/translation";
const { urlValidationError2, requiredError2 } = commonErrors;

interface Props {
  show: boolean;
  milestoneDay: MilestoneDay | null;
  existingDays: number[];
  week: number;
  onHide: () => void;
  onUpdate: (task: MilestoneDay) => void;
  onDelete: (id: MilestoneDay["id"]) => void;
  onAdd: (day: MilestoneDay) => void;
  isMonth: boolean;
  onUploadImage: (file: Blob, fileName: string) => Promise<string>;
}

type FormValues = {
  translations?: {
    [locale: string]: {
      imageUrl: string;
      blurhash?: string;
      text: string;
    };
  };
  day: number;
};

const schema = yup.object().shape({
  imageUrl: yup.string().nullable().url(dayModalMessages.urlValidationText),
  text: yup.string().required(dayModalMessages.requiredValidationText),
});

export const DayModal = ({
  show,
  milestoneDay,
  week,
  existingDays,
  onHide,
  onUpdate: onUpdateMilestoneDay,
  onDelete: onDeleteMilestoneDay,
  onAdd: onAddMilestoneDay,
  isMonth,
  onUploadImage,
}: Props) => {
  const totalDays = maximumWeek * 7;
  const maxDate = totalDays - (week - 1) * 7;
  const minDate = totalDays - week * 7;
  const maxDateforMonth = 31;
  const minDateforMonth = 1;
  const { primaryLocale, currentCountry } = useCountry();

  const dayValidation = yup.object().shape({
    day: yup
      .number()
      .typeError(dayModalMessages.numberTypeErrorMessage)
      .max(
        isMonth ? maxDateforMonth : maxDate,
        `Date should be within ${isMonth ? minDateforMonth : minDate} and ${
          isMonth ? maxDateforMonth : maxDate
        }`
      )
      .min(
        isMonth ? minDateforMonth : minDate,
        `Date should be within ${isMonth ? minDateforMonth : minDate} and ${
          isMonth ? maxDateforMonth : maxDate
        }`
      )
      .required(dayModalMessages.requiredValidationText),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          text: yup
            .string()
            .required(
              `Text (${String(item.key).toUpperCase()}): ${requiredError2}`
            ),
          imageUrl: yup
            .string()
            .nullable()
            .url(`Image URL: ${urlValidationError2}`),
        });
        return acc;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, {} as any)
    ),
  });

  const {
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(schema.concat(dayValidation)),
    mode: "all",
  });

  const [error, setError] = useState<string | null>(null);
  const day = watch("day");
  const translations = watch("translations");

  useEffect(() => {
    if (!milestoneDay) {
      reset({
        translations: {},
        day: 0,
      });
    } else {
      reset({
        translations: milestoneDay.translations,
        day: milestoneDay.day,
      });
    }
    setError(null);
  }, [milestoneDay, reset, show]);

  useEffect(() => {
    setError(null);
  }, [day]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    try {
      const { day, translations } = data;
      const milestoneDataData = {
        day,
        text: translations[primaryLocale.key].text,
        imageUrl: translations[primaryLocale.key].imageUrl,
        blurhash: translations[primaryLocale.key].blurhash,
        translations,
      };

      if (!milestoneDay) {
        if (existingDays.includes(day)) {
          setError(dayModalMessages.existingDayError);
          return;
        }
        onAddMilestoneDay({
          id: uuidv4(),
          day,
          ...milestoneDataData,
        });
      } else {
        if (milestoneDay.day !== day) {
          if (existingDays.includes(day)) {
            setError(dayModalMessages.existingDayError);
            return;
          }
        }
        onUpdateMilestoneDay({
          id: milestoneDay.id,
          day,
          ...milestoneDataData,
        });
      }
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const onDelete = async () => {
    if (milestoneDay !== null) {
      onDeleteMilestoneDay(milestoneDay.id);
    }
  };

  const handleImageUrl = (url: string | null, locale: string) => {
    setValue(`translations.${locale}.imageUrl`, url!, {
      shouldDirty: true,
    });
  };

  const handleBlurhash = (url: string | null, locale: string) => {
    setValue(`translations.${locale}.blurhash`, url!, {
      shouldDirty: true,
    });
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={error}
      onDelete={onDelete}
      enableDelete={!!milestoneDay}
      modalSubTitle={milestoneDay ? `Id - ${milestoneDay?.id}` : ""}
      modalTitle={milestoneDay ? "Update day" : "Add day"}
      localeSupported
    >
      {locale => (
        <>
          <TranslationSection
            locale={locale}
            primaryLocale={primaryLocale}
            translations={translations as TranslationsObject}
            setValue={setValue as SetValueFunction}
            availableFields={["text"]}
            requiredFields={["text"]}
          />
          <BJInputFormItem
            label={`Text (${locale?.label ?? ""})`}
            fieldName={`translations.${locale.key}.text`}
            key={`translations.${locale.key}.text`}
            control={control}
            error={!!errors?.translations?.[locale.key]?.text}
            message={errors?.translations?.[locale.key]?.text?.message}
          />

          <BJInputFormItem
            label={`Day`}
            fieldName={`day`}
            key={`day`}
            control={control}
            error={!!errors?.day}
            message={errors?.day?.message}
            min={isMonth ? minDateforMonth : minDate}
            max={isMonth ? maxDateforMonth : maxDate}
          />
          <div>
            <Typography.Text>{`Image Url (${
              locale?.label ?? ""
            })`}</Typography.Text>
            <DropAndCrop
              title=""
              setUploadUrl={(url: string) => handleImageUrl(url, locale.key)}
              setBlurhash={(url: string) => handleBlurhash(url, locale.key)}
              uploadImage={onUploadImage}
              initialUrl={watch(`translations.${locale.key}.imageUrl`)}
              lockedRatio={AspectRatio.OneToOne}
              defaultCropBoxWidth={300}
              key={`translations.${locale.key}.imageUrl`}
            />
          </div>
        </>
      )}
    </FormModal>
  );
};
