/**
 * translationHelpers.ts
 *
 * A utility module that provides core functions for handling AI translations
 * across the application. This module processes translation API responses,
 * handles form value updates, and prepares data for translation.
 *
 * This module serves as the foundation for the translation system and is used by:
 * - useTranslationHandler hook for component-level translation handling
 * - TranslationSection component for simplified UI integration
 *
 * USAGE:
 * For direct usage in components:
 * ```
 * import {
 *   createTranslationHandler,
 *   prepareTranslationData
 * } from '../utils/translationHelpers';
 *
 * // Create a handler function
 * const translateHandler = createTranslationHandler(
 *   setValue,
 *   ['title', 'description']
 * );
 *
 * // Prepare data for translation
 * const dataForTranslation = prepareTranslationData(
 *   translations,
 *   primaryLocale.key,
 *   ['title', 'description']
 * );
 * ```
 *
 * Typically, you'll use these utilities indirectly through useTranslationHandler
 * or TranslationSection rather than calling them directly.
 */
import { logException } from "./exceptionLogger";
import {
  TranslationResult,
  SetValueFunction,
  TranslationsObject,
  FieldMapping,
} from "../types/translation";

/**
 * Processes translation API response to extract field values
 * Handles different response formats and potential truncation
 *
 * @param value Response from translation API
 * @return Array of translated values in the original field order
 */
export const processTranslationResponse = (
  value: TranslationResult | null | undefined
): string[] => {
  if (!value) return [];

  let translatedValues: string[] = [];

  try {
    // Handle case where the data is direct field objects
    if (
      value.data &&
      typeof value.data === "object" &&
      !Array.isArray(value.data)
    ) {
      // Check if it's a field-based structure like { field_0: "text", field_1: "text" }
      const dataObj = value.data as Record<string, unknown>;
      const fieldKeys = Object.keys(dataObj).filter(key =>
        key.startsWith("field_")
      );

      if (fieldKeys.length > 0) {
        fieldKeys.sort((a, b) => {
          const numA = parseInt(a.replace("field_", ""));
          const numB = parseInt(b.replace("field_", ""));
          return numA - numB;
        });

        translatedValues = fieldKeys.map(key => String(dataObj[key] || ""));
        return translatedValues;
      }
    }

    if (Array.isArray(value.data)) {
      translatedValues = value.data.map(item => String(item || ""));
      return translatedValues;
    }

    // Handle string response that might be JSON
    if (typeof value.data === "string") {
      try {
        const parsedData = JSON.parse(value.data);

        if (typeof parsedData === "object" && !Array.isArray(parsedData)) {
          const fieldKeys = Object.keys(parsedData).filter(key =>
            key.startsWith("field_")
          );

          if (fieldKeys.length > 0) {
            fieldKeys.sort((a, b) => {
              const numA = parseInt(a.replace("field_", ""));
              const numB = parseInt(b.replace("field_", ""));
              return numA - numB;
            });

            translatedValues = fieldKeys.map(key =>
              String(parsedData[key] || "")
            );
            return translatedValues;
          }
        }

        if (Array.isArray(parsedData)) {
          translatedValues = parsedData.map(item => String(item || ""));
          return translatedValues;
        }
      } catch (e) {
        logException(new Error(`Error parsing translation response: ${e}`));
      }
    }

    if (value.data !== undefined) {
      return [String(value.data)];
    }

    return [];
  } catch (error) {
    logException(new Error(`Error processing translation response: ${error}`));
    return [];
  }
};

/**
 * Updates form values with translated content using a field mapping configuration
 *
 * @param translatedValues Array of translated values
 * @param setValue Function to set form values (from React Hook Form)
 * @param fieldMapping Object mapping array indices to form field paths
 */
export const updateFormWithTranslations = (
  translatedValues: string[],
  setValue: SetValueFunction,
  fieldMapping: FieldMapping
): void => {
  // Apply common fields first (if present)
  if (fieldMapping.common) {
    fieldMapping.common.forEach((fieldPath, index) => {
      if (translatedValues[index] !== undefined) {
        setValue(fieldPath, translatedValues[index], { shouldDirty: true });
      }
    });
  }

  // Apply additional mapped fields
  Object.entries(fieldMapping).forEach(([key, paths]) => {
    if (key !== "common") {
      paths.forEach((fieldPath, index) => {
        const valueIndex = index + (fieldMapping.common?.length || 0);
        if (translatedValues[valueIndex] !== undefined) {
          setValue(fieldPath, translatedValues[valueIndex], {
            shouldDirty: true,
          });
        }
      });
    }
  });
};

/**
 * Creates a translation handler function for use with AiTranslator
 * With improved error handling and response processing
 *
 * @param setValue React Hook Form's setValue function
 * @param fieldNames Array of field names to update
 * @return A function that can be passed to AiTranslator's onSetValue prop
 */
export const createTranslationHandler = (
  setValue: SetValueFunction,
  fieldNames: string[] = ["title", "intro", "body"]
) => {
  return (
    translationResult: TranslationResult | null | undefined,
    targetLocale: Locale
  ): void => {
    // Handle empty or invalid results
    if (!translationResult) {
      logException(new Error("Translation failed: Received empty result"));
      return;
    }

    const processedValues = processTranslationResponse(translationResult);

    if (processedValues.length === 0) {
      logException(
        new Error("Translation failed: Could not extract translated values")
      );
      return;
    }

    const targetLanguageKey = targetLocale?.key;

    if (!targetLanguageKey) {
      logException(
        new Error("Translation failed: Could not determine target language")
      );
      return;
    }

    const fieldMapping: FieldMapping = {
      common: fieldNames
        .slice(0, processedValues.length)
        .map(field => `translations.${targetLanguageKey}.${field}`),
    };

    updateFormWithTranslations(processedValues, setValue, fieldMapping);
  };
};

/**
 * Safely prepares data array for the AiTranslator
 *
 * @param translations The translations object
 * @param sourceLocaleKey Key of the source locale
 * @param fieldNames Array of field names to translate
 * @return Sanitized array of values ready for AiTranslator
 */
export const prepareTranslationData = (
  translations: TranslationsObject | undefined,
  sourceLocaleKey: string,
  fieldNames: string[] = ["title", "intro", "body"]
): (string | null)[] => {
  if (!translations || !translations[sourceLocaleKey]) {
    return fieldNames.map((): string | null => null);
  }

  return fieldNames
    .map(field => {
      try {
        const value = translations[sourceLocaleKey][field];
        if (value === undefined || value === null) return null;

        if (typeof value === "string") return value.trim() || null;

        if (Array.isArray(value)) return value.join(", ");

        return String(value);
      } catch (e) {
        return null;
      }
    })
    .filter(val => val !== null) as (string | null)[];
};
